export enum UserRole {
  PATIENT = 'patient',
  SPECIALIST = 'specialist',
}

export enum RegistrationView {
  REGISTER = 'register',
  ACTIVE = 'active',
  CONFIRM = 'confirm',
  SUCCESS = 'success',
}

export enum ResetView {
  RESET = 'reset',
  CHECK = 'check',
  NEW = 'new',
  SUCCESS = 'success',
}

export type UserData = Partial<User>;

export interface User {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  isActive: boolean;
  termsApproved: boolean;
  role: UserRole;
  birthdateDisplayed?: Date;
  birthdate?: string;
  id: string;
  password?: string;
  avatar?: string;
  parent: string;
}

export interface LoginData {
  email: string;
  password: string;
}

export interface UserBasicData {
  id: string;
  role: UserRole;
  email: string;
  avatar: string;
  firstName: string;
  lastName: string;
  phone: string;
  title?: string;
  parent?: string;
}

export interface Code {
  code: string;
}
