import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
  transform(object: KeyValue<string, any>[], orderBy: string): any[] {
    const array: any[] = Object.values(object);
    return array.sort((a, b) =>
      a['value'][orderBy] < b['value'][orderBy]
        ? -1
        : a['value'][orderBy] > b['value'][orderBy]
        ? 1
        : 0
    );
  }
}
