import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import { Observable, from, map } from 'rxjs';

@Injectable()
export class ZipService {
  public upnackZipedPDFs(data: Blob): Observable<any[]> {
    const zip = new JSZip();
    return from(zip.loadAsync(data)).pipe(map(() => Object.values(zip.files)));
  }
}
 