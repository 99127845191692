import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { VisitDTO } from 'src/app/shared/DTOs';
import { TranslationSet } from 'src/app/shared/models';
import { HourVisits } from '../../models';
import { VisitType } from 'src/app/visit/visit.models';

@Component({
  selector: 'cl-app-week[visits]',
  templateUrl: './week.component.html',
  styleUrls: ['./week.component.scss']
})
export class WeekComponent implements OnInit, OnChanges {

  @Input() visits: VisitDTO[] = [];
  @Input() translations!: TranslationSet;
  @Input() initialDayDate?: string;
  @Input() initialDayIndex?: number; 
  @Output() changedWeek = new EventEmitter<string>(); 

  public VisitTypes = VisitType;
  public hourlyMatrix: HourVisits[] = [];
  public daysColumns = ['hour', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  public daysMatrix!: string[];
  public calendarWeekIndex = 0;

  private desiredtDate: string = '';

  ngOnInit(): void {
    this.generateHourlyMatrix();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.desiredtDate) this.generateHourlyMatrix(this.desiredtDate, 0);
  }

  public goToNextWeek(): void {
    this.calendarWeekIndex +=1;

    const saturdayThisWeek = new Date(this.daysMatrix[5])
    const epochMondayNextWeek: number = saturdayThisWeek.setDate(saturdayThisWeek.getDate() + 2);
    this.desiredtDate =  new Date(epochMondayNextWeek).toLocaleDateString('en-CA');

    this.changedWeek.emit(this.desiredtDate);

  }

  public goToPreviousWeek(): void {
    this.calendarWeekIndex -=1;

    const mondayThisWeek: Date = new Date(this.daysMatrix[0]);
    const epochMondayPrevWeek: number = mondayThisWeek.setDate(mondayThisWeek.getDate() -7);
    this.desiredtDate = new Date(epochMondayPrevWeek).toLocaleDateString('en-CA');

    this.changedWeek.emit(this.desiredtDate)
  }

  private setDaysDates(todayIndex: number, todayDateString: string): void {

    this.daysMatrix = Array(6).fill('');    
    this.daysMatrix[todayIndex] = todayDateString;
    this.daysMatrix.forEach((_day: string, index: number) => {

      if (index < todayIndex) {
        const distance = todayIndex - index;
        const todayDate: Date = new Date(todayDateString);
        const epoch: number = todayDate.setDate(todayDate.getDate() - distance);
        const currentDate: string =  new Date(epoch).toLocaleDateString('en-CA');
        this.daysMatrix[index] = currentDate;
      } else if (index > todayIndex) {
        const distance = index - todayIndex;
        const todayDate: Date = new Date(todayDateString);
        const epoch: number = todayDate.setDate(todayDate.getDate() + distance);
        const currentDate: string =  new Date(epoch).toLocaleDateString('en-CA');
        this.daysMatrix[index] = currentDate;
      }
    })
  }

  private generateHourlyMatrix(selectedDate?: string, selectedIndex?: number): void {

    this.hourlyMatrix = Array.from({ length: 9 }, (_, index) => ({ 
        hour: `${9 + index}:00`,
        days: Array(6).fill(null)
    }));

    this.hourlyMatrix[0].hour = `0${this.hourlyMatrix[0].hour}`;

    const today: Date = new Date();
    const todayDateString: string = selectedDate || today.toLocaleDateString('en-CA');
    const todayIndex: number =  selectedDate ? 0 : today.getDay() -1; 

    this.setDaysDates(todayIndex, todayDateString);

    this.visits.forEach((v: VisitDTO) => {
      const hour: string = v.start_date.slice(11, 16);
      const hourItem: HourVisits = this.hourlyMatrix.find((h: HourVisits) => h.hour === hour) as HourVisits;
      const currentDayIndex = this.daysMatrix.findIndex((date: string) => v.start_date.slice(0, 10) === date);
      hourItem.days[currentDayIndex] = v;
    })
  }

}
