import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPTY, Observable, Subscription, catchError, finalize, take, tap } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';

import { UserBasicData, UserRole } from 'src/app/auth/auth.models';
import { AuthAPIService } from 'src/app/auth/services/auth-api.service';
import { FileErrors } from '../../settings.model';
import { SettingsService } from '../../settings.service';
import { AVATAR_PATH } from 'src/app/auth/auth.const';
import { MESSAGE_TYPE } from 'src/app/shared/models';
import { SNACKBAR_CONFIG } from 'src/app/shared/const';

@Component({
  selector: 'cl-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  @Input() translations: any; // type

  public avatarUrl!: string;

  public form: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.pattern('[0-9]{9,15}')]),
    email: new FormControl('', [Validators.required, Validators.email]),
    avatar: new FormControl(''),  
    title: new FormControl(''), 
  });

  public imageErrors: FileErrors = {
    size: '',
    type: '',
  }

  private user: UserBasicData | null = null;
  private subscriptions: Subscription = new Subscription();
  private avatar: Blob | null = null;

  constructor(
    private snackBar: MatSnackBar,
    private authAPIService: AuthAPIService, 
    private settingsService: SettingsService) {}

  ngOnInit(): void {
    this.subscriptions.add(this.getProfileData().subscribe());
    this.subscriptions.add(this.getAvatar().subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public save(): void {
    if (!this.form.valid) { return }
      this.subscriptions.add(this.authAPIService.updateActiveUser(this.form.value)
        .pipe(
          tap((u: UserBasicData) => this.snackBar.open(
            `${this.translations.content.profile.saved}: ${u.firstName} ${u.lastName}`, 
            '', 
            SNACKBAR_CONFIG)),
          catchError((_e) => { 
            this.snackBar.open(
              `${this.translations.content.profile.saveError}: ${this.user!.firstName} ${this.user!.lastName}`, 
              '', 
              { ...SNACKBAR_CONFIG, panelClass: [MESSAGE_TYPE.ERROR] }); 
            return EMPTY;
          })  
        )
        .subscribe());
  }

  public cancel(): void {
    this.fillForm(this.user);
  }

  setImageFromInput(e: any): void {
   const img: Blob = e.target!.files[0];
   this.setImage(img);
   e.target!.value= '';
  }

  public setImage(img: Blob): void {
    if (!img) { return }
    this.avatar = img;

    this.settingsService.clearImageErrors(this.imageErrors);
    this.settingsService.validateImage(this.avatar, this.imageErrors, this.translations);
    if (this.imageErrors.size || this.imageErrors.type) {
      return
    }

    this.setAvatarPreview();

    const imageData: FormData = new FormData();
    imageData.append('avatar', this.avatar, this.avatar.name);
    
    this.subscriptions.add(this.authAPIService.saveAvatar(imageData)
        .pipe(
          tap((_resp: Object) => this.authAPIService.$avatar.next(this.avatar)),
          tap((_resp: Object) => this.snackBar.open(
            `${this.translations.content.profile.savedAvatar}: ${this.user!.firstName} ${this.user!.lastName}`, 
            '', 
            SNACKBAR_CONFIG)),
          catchError((_e) => { 
            this.snackBar.open(
              `${this.translations.content.profile.saveError}: ${this.user!.firstName} ${this.user!.lastName}`, 
              '', 
              { ...SNACKBAR_CONFIG, panelClass: [MESSAGE_TYPE.ERROR] }); 
            return EMPTY
          })  )
        .subscribe());
  }

  private setAvatarPreview(): void {
    const reader = new FileReader();
    if (this.avatar) {
      reader.readAsDataURL(this.avatar);
      reader.onload = (event) => this.avatarUrl = event.target?.result as string;
    } else {
      this.avatarUrl = AVATAR_PATH;
    }
  }

  private getAvatar(): Observable<Blob | null> {
    return this.authAPIService.$avatar.pipe(
      take(1),
      tap((image: Blob | null) => {
         this.avatar = image;
         this.setAvatarPreview();
        }),
      finalize(() => this.setAvatarPreview())  
    );
  }

  private getProfileData(): Observable<UserBasicData | null> {
    return this.authAPIService.$activeUser.pipe(
      tap((u: UserBasicData| null) => this.user = u),
      tap((u: UserBasicData| null) => this.fillForm(u)),
      tap(() => { 
        if (this.user?.role === UserRole.PATIENT ) {
        delete this.translations.content.profile.salutationOptions.doc;
        delete this.translations.content.profile.salutationOptions.naturopath;
        }
      })
    )
  }

  private fillForm(user: UserBasicData | null): void {
    if (!user) { return }
      this.form.controls['firstName'].setValue(user.firstName);
      this.form.controls['lastName'].setValue(user.lastName);
      this.form.controls['phone'].setValue(user.phone);
      this.form.controls['email'].setValue(user.email);
      this.form.controls['avatar'].setValue(user.avatar);
      this.form.controls['title'].setValue(user.title || '');
  }

}
