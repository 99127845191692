import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Observable, Subscription, catchError, of, take, tap } from 'rxjs';
import { MESSAGE_TYPE, SwitchableItem, TranslationSet, VisitsViewRange } from '../shared/models';
import { HomeTranslationsService } from './services/translations.service';
import { UserBasicData, UserRole } from '../auth/auth.models';
import { CalendarAPIService } from '../shared/services/calendar-api.service';
import { AuthAPIService } from '../auth/services/auth-api.service';
import { VisitDTO } from '../shared/DTOs';
import { VisitType } from '../visit/visit.models';

@Component({
  selector: 'cl-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  public readonly VisitTypes = Object.values(VisitType);
  public text!: TranslationSet;
  public visitsColumns = ['date', 'patient', 'phone', 'type', 'action'];
  public visitsFilters: SwitchableItem[] = [];
  public calendarVisits: VisitDTO[] = [];
  public user!: UserBasicData;
  public UserRole = UserRole;
  public VisitView = VisitsViewRange;
  public selectedView: VisitsViewRange = VisitsViewRange.TODAY;
  public messageTypes = MESSAGE_TYPE;
  public loadedData = false;

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private authAPIService: AuthAPIService,
    private translationService: HomeTranslationsService,
    private calendarAPIService: CalendarAPIService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.getTranslations().subscribe());
    this.subscriptions.add(this.getUser().subscribe());
    this.subscriptions.add(this.getDaysVisits().subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public startVisit(v: VisitDTO): void {

    const userExtras = {
      [VisitType.CONTROL]: { userId: v.patient?.id },
      [VisitType.BASIC]: {  email: v.patient?.email,
                            firstName: v.patient?.firstName,
                            lastName: v.patient?.lastName,
                            phone: v.patient?.phone,
                            parent: v.patient?.parent,
                            isActive: false,
                            termsApproved: false,
                            role: UserRole.PATIENT,
                            id: v.patient?.id
                          }       
    } 

    const extras: NavigationExtras = { state: {
        visitData: {
          type: v.type
        },
        userData: userExtras[v.type]

    }}

    this.router.navigate(['/visit'], extras);
  }

  public onWeekChanged(selectedDate: string): void {
    this.subscriptions.add(this.getWeekVisits(selectedDate).subscribe());
  }

  public changeVisitsFilter(selectedIndex: number): void {
    this.visitsFilters.forEach((item: SwitchableItem, index: number) => item.applied = selectedIndex === index);
    this.loadedData = false;
    switch (selectedIndex) {
      case 0:
        this.selectedView = VisitsViewRange.TODAY;
        this.subscriptions.add(this.getDaysVisits(this.selectedView).subscribe())
        break;
      case 1:
        this.selectedView = VisitsViewRange.TOMORROW;
        this.subscriptions.add(this.getDaysVisits(this.selectedView).subscribe())
        break;  
      case 2:
        this.subscriptions.add(this.getWeekVisits().subscribe());
        break;
      default:
        break;
    }
  }

  private getTranslations(): Observable<TranslationSet> {
    return this.translationService.getHomeTranslations()
      .pipe(
        tap((t: TranslationSet) => (this.text = t)),
        tap(() => {
          this.visitsFilters = [
            { applied: false, text: this.text[VisitsViewRange.TODAY] }, 
            { applied: false, text: this.text[VisitsViewRange.TOMORROW] },
            { applied: false, text: this.text[VisitsViewRange.WEEK] }
          ]   
        })  
      )
  }

  private getWeekVisits(selectedDate?: string): Observable<any> {
    const currentDate: string =  selectedDate || this.prepareCurrentDate(VisitsViewRange.WEEK);
    return this.calendarAPIService.getWeekVisits(currentDate, this.user.email).pipe(
      take(1),
      tap((visits: VisitDTO[]) => {
        this.calendarVisits = visits;
        this.loadedData = true;
        this.selectedView = VisitsViewRange.WEEK;
      }),
      catchError((_err) => { 
        this.calendarVisits = []; 
        this.loadedData = true;
        this.selectedView = VisitsViewRange.WEEK;
        return of([]) 
      })
    );
  }

  private getDaysVisits(range: VisitsViewRange = VisitsViewRange.TODAY): Observable<Record<string, any>> {
    const currentDate: string = this.prepareCurrentDate(range);

    return this.calendarAPIService.getDaysVists(currentDate, this.user.email).pipe(
      take(1),
      tap((visits: VisitDTO[]) => this.prepareVisits(visits)),
      tap((visits: VisitDTO[]) => { 
        this.calendarVisits = visits;
        this.loadedData = true;
      }),
      catchError((_err) => { 
        this.calendarVisits = [];
        this.loadedData = true; 
        return of([]) 
      })
    )
  }

  private prepareCurrentDate(rate: VisitsViewRange): string {
    let currentDate = '';
    const rawDate: Date = new Date();

    switch (rate) {
      case VisitsViewRange.TODAY:
      case VisitsViewRange.WEEK:
        currentDate = rawDate.toLocaleDateString('en-CA');
        break;
      case VisitsViewRange.TOMORROW:
        const tomorrowEpoch: number = rawDate.setDate(rawDate.getDate() + 1);
        currentDate =  new Date(tomorrowEpoch).toLocaleDateString('en-CA');
        break;
      default:
        break;
    }
    return currentDate;
  }

  private prepareVisits(visits: VisitDTO[]): void {

    const currentHour =  new Date().toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: false });

    visits.forEach((v: VisitDTO) => {
      const hourStartIndex: number = v.start_date.indexOf(' ') + 1;
      v.hour =  v.start_date.substring(hourStartIndex, hourStartIndex + 5);
      v.expired = this.selectedView === VisitsViewRange.TODAY ? v.hour < currentHour : false;
    });
  }

  private getUser(): Observable<UserBasicData | null> {
    return this.authAPIService.$activeUser.pipe(
      take(1),
      tap((u: UserBasicData | null) => { 
        if (u?.role === UserRole.PATIENT) {
          const extras: NavigationExtras = { state: { 'patient': u, 'isSpecialist': false } }
          this.router.navigate([`patients/${u.id}`], extras);
        } else if (u) {
          this.user = u;
        }}),
    )
  }
  
}