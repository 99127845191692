
<div class="user-data">
    <div>
      <img
        class="avatar"
        [src]="avatarURL"
        alt="avatar"
      />
    </div>
    <div>
      <div class="title">
        {{ user.firstName }} {{ user.lastName }}
      </div>
      <div class="extra">
        {{ translations["birthDate"] }}:
        {{ user.birthdateDisplayed | date : "dd.MM.yyyy" : "pl" }},
        <span *ngIf="user.birthdateDisplayed"
          >
          {{ translations["age"] }}:
          {{ user.birthdateDisplayed | age }}
        </span>
        <ng-container *ngIf="canEditDate">
          <mat-datepicker #picker></mat-datepicker>
          <input
            matInput
            [matDatepicker]="picker"
            [max]="todaysDate"
            (dateInput)="setBirthDay($event)"
            class="hide"
          />
          <span (click)="picker.open()" class="icon-container">
            <img src="assets/icons/edit.svg" alt="edit-icon" class="icon" />
          </span>
        </ng-container>
      </div>
    </div>
  </div>