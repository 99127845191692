import { environment } from "src/environments/environment";
import { MESSAGE_TYPE } from "./models";

export const VERSION = 'v1';
export const BASE_API_URL = environment.baseApiUrl;
export const EXTERNAL_API_CALENDAR = environment.externalCalendarUrlApi;

export const CUSTOMER_SERVICE_EMAIL = 'emil@swieca.es';

export const ICON_NAMES = {
  mail: 'mail',
  check: 'check',
  key: 'key',
};

export const SNACKBAR_CONFIG = {
  duration: 4000, 
  verticalPosition: 'top' as const, 
  panelClass: [MESSAGE_TYPE.SUCCESS]
}

export const PATHOGENES_MOCK = [
  {
    title: 'System immunologiczny',
    diseases: [
      {
        description: 'Opis objawów i leczenia  Tęgoryjec',
        frequencies: [
          {
            dataSourceId: 1,
            disease_id: '9b1374c3-55ee-4d59-9ebd-1b4de80b0590',
            id: '987c08b6-5a2d-49e2-b388-81e23463f001',
            is_hidden: false,
            name: '22',
            polarity: 3,
            value: 22,
            wave: 2,
          },
          {
            dataSourceId: 1,
            disease_id: '9b1374c3-55ee-4d59-9ebd-1b4de80b0530',
            id: '987c08b6-5a2d-49e2-b388-81e23463f031',
            is_hidden: false,
            name: '23',
            polarity: 4,
            value: 21,
            wave: 6,
          },
          {
            dataSourceId: 1,
            disease_id: '9b1374c3-55ee-4d59-9ebd-134de80b0530',
            id: '987c08b6-5a2d-49e2-b388-83e23463f031',
            is_hidden: false,
            name: '27',
            polarity: 7,
            value: 27,
            wave: 7,
          },
        ],
        id: '00b91c5c-469c-4718-85cb-381f29be5589',
        name: 'Tęgoryjec',
        selected: true,
        symptoms: [],
        bodySystems: [
          { id: 2, name: 'System krwionośny' },
          { id: 3, name: 'System nerwowy' },
        ],
        dataSources: [
          { id: 1, name: 'Doctor Volovyk' },
          { id: 2, name: 'Baza ETDFL' },
        ],
        categories: [
          { id: 1, name: 'pasożyty', data_source: 1 },
          { id: 6, name: 'pasożyty', data_source: 2 },
        ],
        subCategories: [
          { id: 5, name: 'tasiemce', category: 1 },
          { id: 7, name: 'tasiemce', category: 6 },
        ],
      },
      {
        // bodySystemId: 1,
        description: 'Opis objawów i leczenia Glista',
        frequencies: [],
        id: '70971758-8875-4830-af32-0ef3fa960899',
        name: 'Glista',
        selected: false,
        symptoms: [],
        bodySystems: [
          { id: 2, name: 'System krwionośny' },
          { id: 3, name: 'System nerwowy' },
        ],
        dataSources: [
          { id: 1, name: 'Doctor Volovyk' },
          { id: 2, name: 'Baza ETDFL' },
        ],
        categories: [],
        subCategories: [],
      },
    ],
  },
  {
    title: 'System krwionośny',
    diseases: [
      {
        // bodySystemId: 2,
        description: 'Opis objawów i leczenia  Tasiemiec nieuzbrojony',
        frequencies: [
          {
            dataSourceId: 1,
            disease_id: '9b1374c3-55ee-4d59-9ebd-1b4de80b0590',
            id: '987c08b6-5a2d-49e2-b388-81e23463f001',
            is_hidden: false,
            name: '13',
            polarity: 3,
            value: 22,
            wave: 2,
          },
          {
            dataSourceId: 1,
            disease_id: '9b1374c3-55ee-4d59-9ebd-1b4de80b0530',
            id: '987c08b6-5a2d-49e2-b388-81e23463f031',
            is_hidden: false,
            name: '14',
            polarity: 4,
            value: 21,
            wave: 6,
          },
          {
            dataSourceId: 1,
            disease_id: '9b1374c3-55ee-4d59-9ebd-134de80b0530',
            id: '987c08b6-5a2d-49e2-b388-83e23463f031',
            is_hidden: false,
            name: '17',
            polarity: 7,
            value: 27,
            wave: 7,
          },
        ],
        id: '9b1374c3-55ee-4d59-9ebd-1b4de80b0590',
        name: 'Tasiemiec nieuzbrojony',
        selected: true,
        symptoms: [],
        bodySystems: [
          { id: 2, name: 'System krwionośny' },
          { id: 3, name: 'System nerwowy' },
        ],
        dataSources: [
          { id: 1, name: 'Doctor Volovyk' },
          { id: 2, name: 'Baza ETDFL' },
        ],
        categories: [
          { id: 1, name: 'pasożytyT', data_source: 1 },
          { id: 6, name: 'pasożyty2', data_source: 2 },
        ],
        subCategories: [
          { id: 5, name: 'tasiemceT', category: 1 },
          { id: 7, name: 'tasiemce2', category: 6 },
        ],
      },
      {
        // bodySystemId: 2,
        description: 'Opis objawów i leczenia  NW',
        frequencies: [],
        id: '8d80f860-34d7-42a0-a6a0-9105dd231a6e',
        name: 'Niewydolność wielonarządowa',
        selected: false,
        symptoms: [],
        bodySystems: [
          { id: 2, name: 'System krwionośny' },
          { id: 3, name: 'System nerwowy' },
        ],
        dataSources: [
          { id: 1, name: 'Doctor Volovyk' },
          { id: 2, name: 'Baza ETDFL' },
        ],
        categories: [],
        subCategories: [],
      },
    ],
  },
];
