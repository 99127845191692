<div *ngIf="translations && patient" class="visit">
  <div class="visit-header">
    <div class="header-top">
      <img
        (click)="goBack()"
        class="icon"
        src="assets/icons/arrow-left-violet.svg"
        alt="back"
      />
      {{ translations["visit"]["back"] }}
    </div>
    <div class="header-content">
      <cl-user-data 
        [user]="patient" 
        [canEditDate]="true">
      </cl-user-data>
      
      <div class="content-right">
        <div class="title">{{ todaysDate | date : "dd MMMM yyyy" : "pl" }}</div>
        <div *ngIf="visit.type === visitType.BASIC" class="extra">{{ translations["visit"]["visitBasic"] }}</div>
        <div *ngIf="visit.type === visitType.CONTROL" class="extra">{{ translations["visit"]["visitControl"] }}</div>
      </div>
    </div>
    <cl-buttons-bar
      *ngIf="displayPreviewVisitsHistory"
      (openData)="getPreviousVisits()"
      (selectedButton)="displayPDFModal($event)"
      [text]="translations['visit']['historyPreview']"
      [items]="previousVisits"
    >
    </cl-buttons-bar>
  </div>

  <div class="stepper">
    <mat-tab-group
      #tabs
      [(selectedIndex)]="tabIndex"
      (selectedTabChange)="updateTabBtns(tabs)"
      mat-stretch-tabs="false"
      mat-align-tabs="start"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          <button class="reset button-label">
            {{ translations["visit"]["generalParams"] }}
          </button>
        </ng-template>
        <ng-container *ngIf="healthFactors.length">
          <cl-health-factors-table
            [healthFactors]="healthFactors"
            [translations]="translations['params']"
          ></cl-health-factors-table>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <button class="reset button-label">
            {{ translations["visit"]["pathogenes"] }}
          </button>
        </ng-template>
        <ng-container *ngIf="bodySystems.length">
          <cl-pathogens
            [bodySystems]="bodySystems"
            [selectionCards]="selectionCards"
            (pathogenChange)="updateTabBtns(tabs)"
          ></cl-pathogens>
        </ng-container>
      </mat-tab>

      <mat-tab [disabled]="!hasPathogens">
        <ng-template mat-tab-label>
          <button [disabled]="!hasPathogens" class="reset button-label">
            {{ translations["visit"]["recommendations"] }}
          </button>
        </ng-template>
        <cl-recommendations
          [tabIndex]="tabIndex"
          [pathogenesCards]="selectionCards"
          (recommendationsEmiter)="recommendationsUpdate($event)"
        ></cl-recommendations>
      </mat-tab>
      <mat-tab [disabled]="!recommendations.length">
        <ng-template mat-tab-label>
          <button
            [disabled]="!recommendations.length"
            class="reset button-label"
          >
            {{ translations["visit"]["recommendationsOther"] }}
          </button>
        </ng-template>
        <cl-products
          [recommendations]="recommendations"
        ></cl-products>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <button class="reset button-label">
            {{ translations["visit"]["annotations"] }}
          </button>
        </ng-template>
        <cl-annotations
          (annotationsEmitt)="annotationsUpdate($event)"
        ></cl-annotations>
      </mat-tab>

      <mat-tab [disabled]="!visit.id">
        <ng-template mat-tab-label>
          <button [disabled]="!visit.id" class="reset button-label">
            {{ translations["visit"]["printouts"] }}
          </button>
        </ng-template>
        <ng-container *ngIf="visit.id">
          <cl-printouts
            [visitId]="visit.id"
            [patientEmail]="patient.email"
            [parentId]="patient.parent"
          ></cl-printouts>
        </ng-container>
      </mat-tab>

      <mat-tab disabled="true">
        <ng-template mat-tab-label>
          <button class="button-action neutral">
            {{ translations["visit"]["cancell"] }}
          </button>
        </ng-template>
      </mat-tab>
      <mat-tab disabled="true">
        <ng-template mat-tab-label>
          <button (click)="save()" class="button-action">
            {{ translations["visit"]["save"] }}
          </button>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

    <div class="stepper-actions">
      <button
        *ngIf="!isFirstTab"
        (click)="navigateTabs(tabs, false)"
        [disabled]="disableBackTab"
        class="button-action neutral"
      >
        <img
          class="btn-icon mr"
          src="assets/icons/arrow-left-gray.svg"
          alt="back"
        />
        {{ translations["visit"]["botttomBack"] }}
      </button>
      <button
        *ngIf="!isLastTab"
        [disabled]="disableForwardTab"
        (click)="navigateTabs(tabs, true)"
        class="button-action neutral ml"
      >
        {{ translations["visit"]["botttomNext"] }}
        <img
          class="btn-icon ml"
          src="assets/icons/arrow-right-gray.svg"
          alt="back"
        />
      </button>
    </div>
  </div>
</div>
