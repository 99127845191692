import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PDFFile } from '../../models';

@Component({
  selector: 'cl-buttons-bar',
  templateUrl: './buttons-bar.component.html',
  styleUrls: ['./buttons-bar.component.scss'],
})
export class ButtonsBarComponent {
  @Input() text: string = '';
  @Input() items: PDFFile[] = [];
  @Output() openData: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectedButton: EventEmitter<number> = new EventEmitter<number>();

  public isOpen = false;
  public showButtons = false;

  public toggleOpen(): void {
    setTimeout(() => {
      this.showButtons = !this.showButtons;
    }, 800);
    this.isOpen = !this.isOpen;
    this.isOpen && this.openData.emit();
  }

  public select(index: number): void {
    this.selectedButton.emit(index);
  }
}
