<div class="annotations">
  <div class="header">
    <div class="header-title">{{ text.header }}</div>
    <div class="header-extra">
      {{ text.extra }}
    </div>
  </div>
  <div class="content">
    <textarea
      [(ngModel)]="annotations"
      (input)="save()"
      placeholder="{{ text.placeholder }}"
      class="textarea"
      matInput
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="10"
      cdkAutosizeMaxRows="15"
    ></textarea>
    <div class="btn-wrap">
      <button (click)="save()" class="btn">{{ text.save }}</button>
    </div>
  </div>
</div>
