import { Pipe, PipeTransform, LOCALE_ID, Inject, } from "@angular/core";
import { Country } from "country-list-with-dial-code-and-flag";
import { i18Country } from "../models";

@Pipe({ name: 'i18country'})
export class i18CountriesPipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) public locale: string
  ) { }

  transform(countries: Country[]): i18Country[] {
    const i18countries = require("i18n-iso-countries");
    i18countries.registerLocale(require(`i18n-iso-countries/langs/${this.locale}.json`));
    const countriesTranslations = i18countries.getNames(this.locale, {select: "official"})

    countries.forEach((country: i18Country) => {
      country.i18Name = countriesTranslations[country.code];
    })
    return countries;
  }

} 