import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'age', standalone: true })
export class AgePipe implements PipeTransform {
  transform(birth: Date): string {
    if (birth.getFullYear() === 1) {
      return '0 lat';
    }

    const getAge = () => {
      const today = new Date();
      let age: number = today.getFullYear() - birth.getFullYear();

      const monthDiff = today.getMonth() - birth.getMonth();

      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birth.getDate())
      ) {
        age--;
      }

      return age;
    };

    let years = '';
    const age = getAge();

    if (age === 1) {
      years = 'rok'
    } else if ([2,3,4].includes(age) || (age > 20 && [2,3,4].includes(age % 10))) {
      years = 'lata'
    } else {
      years = 'lat';
    }

    return `${age} ${years}`;
  }
}
