<div class="settings">
    <div class="title">{{text.title}}</div>
    <cl-buttons-switch 
        [items]="tabs" 
        (selectedButton)="switchTab($event)"
        class="buttons" >
    </cl-buttons-switch>
    <cl-profile 
        class="content"
        *ngIf="tabs[0].applied"
        [translations]="text">
    </cl-profile>
    <cl-company
        class="content hc"
        *ngIf="tabs[1] && tabs[1].applied" 
        [translations]="text">
    </cl-company>
</div>
