import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { EXTERNAL_API_CALENDAR } from "../const";
import { UserDTO, VisitDTO } from "../DTOs";
import { UserBasicData, UserRole } from "src/app/auth/auth.models";

type WPVisitDTO = VisitDTO & { customer: UserDTO[] }
type MultiVisit = [number, WPVisitDTO]

@Injectable()
export class CalendarAPIService {
  constructor(private http: HttpClient) {}

    public getPatientVisits(specialistEmail: string, patientId: string): Observable<VisitDTO[]> {
      const params = new HttpParams().set('specialist_email', specialistEmail).set('patient_id', patientId);
      const options = this.getOptions();
      options['params'] = params;

      return this.http.get<Record<string, any>>(`${EXTERNAL_API_CALENDAR}/getpatientsvisits`, options)
        .pipe(map(resp => resp['data']))
    }

    public getDaysVists(currentDate: string, userEmail: string): Observable<VisitDTO[]> {

      const params = new HttpParams().set('current_date', currentDate).set('staff_email', userEmail)
      const options = this.getOptions();
      options['params'] = params; 

      return this.http.get<Record<string, any>>(`${EXTERNAL_API_CALENDAR}/getdayvisits`, options)
        .pipe(
          map((res: Record<string, any>) => res['data'] ),
          map((res: WPVisitDTO[]) => this.splitMultiVisits(res)),
          map((res: VisitDTO[]) => {
            res.map((v: VisitDTO) => v.patient = this.mapUserDTO(v.customer));
            return res; 
          }),
        )     
    }

    public getWeekVisits(currentDate: string, userEmail: string): Observable<VisitDTO[]> {

      const params = new HttpParams().set('current_date', currentDate).set('staff_email', userEmail)
      const options = this.getOptions();
      options['params'] = params;

      return this.http.get<any>(`${EXTERNAL_API_CALENDAR}/getweekvisits`, options)
        .pipe(
          map((res: Record<string, any>) => res['data']),
          map((res: WPVisitDTO[]) => this.splitMultiVisits(res)),
          map((res: VisitDTO[]) => {
            res.map((v: VisitDTO) => v.patient = this.mapUserDTO(v.customer));
            return res;
          })
        );
    }

  private mapUserDTO(user: UserDTO): UserBasicData {
    return {
      id: user.id,
      role: UserRole.PATIENT,
      email: user.email,
      avatar: '',
      firstName: user.first_name,
      lastName: user.last_name,
      phone: user.phone,
      parent: user.parent_id
    }
  }

  private splitMultiVisits(visits: WPVisitDTO[]): VisitDTO[] {
    const resultVisits: VisitDTO[] = [...visits];
    let multiVisits: MultiVisit[] = [];

    visits.forEach((v: WPVisitDTO, index: number) => v.customer.length > 1 ? multiVisits.push([index, v]) : resultVisits[index].customer = v.customer[0])

    let totalChildrenCounter = 0;
    let childrenCounter = 1;

    multiVisits.forEach((v: MultiVisit) => {

      v[1].customer.forEach((c: UserDTO, index: number) => {

        if (index > 0) {
          const start = new Date(v[1].start_date);
          const up = new Date(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours() +index, start.getMinutes())
          const offset = up.getTimezoneOffset();
          const newDate = new Date(up.getTime() - offset * 60000).toISOString().slice(0, 19).replace('T', ' ');
          const newVisit = { ...v[1], customer: c, id: `${v[1].id}-${index}`, start_date: newDate}

          resultVisits.splice(v[0] + childrenCounter, 0, newVisit)

          ++childrenCounter;
          ++totalChildrenCounter;
        } else {
          resultVisits[v[0] + totalChildrenCounter ].customer = c;
        }
      })

    })
    return resultVisits;
  }

  private getOptions(customHeaders?: HttpHeaders): Record<string, unknown> {
    const headers = customHeaders || new HttpHeaders().set('content-type', 'application/json');
    const options = { headers: headers, withCredentials: true, params: {} };
    return options;
  }  

}