import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPTY, Observable, Subscription, catchError, finalize, take, tap } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import CountryList from 'country-list-with-dial-code-and-flag';
import CountryFlagSvg from 'country-list-with-dial-code-and-flag/dist/flag-svg';
import { Buffer } from 'buffer';
import { MatSelectChange } from '@angular/material/select';

import { MESSAGE_TYPE, i18Country } from 'src/app/shared/models';
import { Clinic, FileErrors } from '../../settings.model';
import { SettingsService } from '../../settings.service';
import { AuthAPIService } from 'src/app/auth/services/auth-api.service';
import { LOGO_PATH } from 'src/app/auth/auth.const';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACKBAR_CONFIG } from 'src/app/shared/const';

@Component({
  selector: 'cl-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, OnDestroy {
  @Input() translations: any; // type

  public countries: i18Country[] = [];
  public descriptionLimit = 250;
  public descriptionLeft = 250;
  public selectedCountryFlag: string = '';  
  public logoUrl!: string;  
  public imageErrors: FileErrors = {
    size: '',
    type: '',
  }
  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    logo: new FormControl(''),
    street: new FormControl(''),
    city: new FormControl(''),
    zip: new FormControl(''),
    country: new FormControl(''),
  });

  private subscriptions: Subscription = new Subscription();
  private logo: Blob | null = null;

  constructor(
    private domSanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private authAPIService: AuthAPIService, 
    private settingsService: SettingsService) {}

  ngOnInit(): void {
    this.subscriptions.add(this.getCompanyData().subscribe());
    this.subscriptions.add(this.getLogo().subscribe());
    this.getPrepareCountries();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public save(): void {
    if (!this.form.valid) { return }
    this.subscriptions.add(this.authAPIService.updateActiveUserCompany(this.form.value as Clinic)
      .pipe(
        tap((c: Clinic) => this.snackBar.open(
          `${this.translations.content.company.saved}: ${c.name}`, 
          '', 
          SNACKBAR_CONFIG)),
          catchError((_e) => { 
            this.snackBar.open(
              `${this.translations.content.company.saveError}: ${this.form.value.name}`, 
              '', 
              { ...SNACKBAR_CONFIG, panelClass: [MESSAGE_TYPE.ERROR] }); 
            return EMPTY;
          })
        )
      .subscribe())
  }

  setImageFromInput(e: any): void {
    const img: Blob = e.target!.files[0];
    this.setImage(img);
    e.target!.value= '';
   }

  public setImage(img: Blob): void {
    if (!img) { return }
    this.logo = img;

    this.settingsService.clearImageErrors(this.imageErrors);
    this.settingsService.validateImage(this.logo, this.imageErrors, this.translations);
    if (this.imageErrors.size || this.imageErrors.type) {
      return
    }

    this.setLogoPreview();

    const imageData: FormData = new FormData();
    imageData.append('logo', this.logo, this.logo.name);
    
    this.subscriptions.add(this.authAPIService.saveLogo(imageData)
        .pipe(
          tap((_resp: Object) => this.authAPIService.$logo.next(this.logo)),
          tap((_resp: Object) => this.snackBar.open(
            `${this.translations.content.company.savedLogo}: ${this.form.value.name}`, 
            '', 
            SNACKBAR_CONFIG)),     
          catchError((_e) => { 
            this.snackBar.open(
              `${this.translations.content.company.saveError}: ${this.form.value.name}`, 
              '', 
              { ...SNACKBAR_CONFIG, panelClass: [MESSAGE_TYPE.ERROR] }); 
            return EMPTY;
          }))
        .subscribe());
  }

  public recalculateDescriptionLimit(): void {
    this.descriptionLeft = this.descriptionLimit - (this.form.controls.description.value?.length || 0);
  }

  public setSelectedCountryFlag(e: MatSelectChange): void {
    this.selectedCountryFlag = this.countries.find((c: i18Country) => c.i18Name === e.value)?.icon;
  }

  private getCompanyData(): Observable<Clinic> {
    return this.authAPIService.getAciveUserCompany().pipe(
      tap((c: Clinic) => this.fillForm(c) )
    )
  }

  private setLogoPreview(): void {
    const reader = new FileReader();
    if (this.logo) { 
      reader.readAsDataURL(this.logo);
      reader.onload = (event) => this.logoUrl = event.target?.result as string
    } else {
      this.logoUrl = LOGO_PATH;
    }
  }

  private getLogo(): Observable<Blob | null> {
    return this.authAPIService.$logo.pipe(
      take(1),
      tap((image: Blob | null) => this.logo = image ),
      finalize(() => this.setLogoPreview())
    )
  }

  private getPrepareCountries(): void {
    this.countries = CountryList.getAll().map((c: i18Country) => { 
      const svg = CountryFlagSvg[c['code']];
      const buffer = Buffer.from(svg)
      const base64string = buffer.toString('base64');
      c['icon'] = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/svg+xml;base64,${base64string}`);
      return c;
    });

    this.sortProrityCountries(this.countries);
  }

  private sortProrityCountries(countries: i18Country[]): void {
    const priorityCodes = ['PL', 'ES', 'GB', 'UA', 'RU'];
    const priorityCountries: i18Country[] = [];
    priorityCodes.forEach((code: string) => {
      const c = countries.find((country: i18Country) => country['code'] === code);
      if (c) priorityCountries.push(c);
    });
    this.countries = [...priorityCountries, ...countries];
  }

  private fillForm(company: Clinic): void {
    if (!company) { return }
    this.form.controls['name'].setValue(company.name);
    this.form.controls['description'].setValue(company.description);
    this.form.controls['street'].setValue(company.street);
    this.form.controls['city'].setValue(company.city);
    this.form.controls['zip'].setValue(company.zip);
    this.form.controls['country'].setValue(company.country);
  }
}
