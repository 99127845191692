<div *ngIf="bodySystems.length" class="pathogenes">
  <div class="searchbox global-override-primary">
    <div class="searchobox-header">
      <div class="title">Wyszukiwanie</div>
      <div class="extra">Wybierz opcje aby wyszukać patogeny do badania</div>
    </div>

    <div class="search-item">
      <div class="label">Wybierz układ autonomiczny</div>
      <mat-form-field class="select" appearance="outline">
        <mat-label> Wybierz </mat-label>
        <mat-select
          (selectionChange)="selectedSystem($event)"
          [disabled]="!bodySystems.length || bodySystemsSelectDisabled"
          [(ngModel)]="bodySystemsInitial"
        >
          <mat-option
            *ngFor="let system of bodySystems"
            [value]="system?.name"
            [class.hide]="system.selected"
            >{{ system?.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <!-- TODO: button was hidden for first release -->
      <!-- <button class="btn" (click)="test()">
        <img class="icon" src="assets/icons/plus.svg" alt="add" />
        Dodaj
      </button> -->
    </div>

    <div class="search-item">
      <div class="label">Wybierz patogen</div>
      <mat-form-field class="select" appearance="outline">
        <mat-label> Wybierz </mat-label>
        <mat-select
          (selectionChange)="selectedPathogen($event)"
          [disabled]="!pathogenes.length || pathogenesSelectDisabled"
          [(ngModel)]="pathogensInitial"
        >
          <mat-option
            *ngFor="
              let disease of selectionCards[selectionCards.length - 1]?.diseases
            "
            [value]="disease.name"
            [class.hide]="disease.selected"
            >{{ disease.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <!-- TODO: button was hidden for first release -->
      <!-- <button class="btn">
        <img class="icon" src="assets/icons/plus.svg" alt="add" />
        Dodaj
      </button> -->
    </div>
  </div>

  <div
    cdkDropList
    [cdkDropListData]="selectionCards"
    (cdkDropListDropped)="drop($event)"
    class="dragarea"
  >
    <div
      *ngFor="let card of selectionCards; let i = index"
      cdkDragLockAxis="y"
      cdkDrag
      class="card"
    >
      <div class="card-bell cm">
        <img class="icon" src="assets/icons/items.svg" alt="icon" />
      </div>
      <div class="card-content">
        <div class="content-header cm">
          <div class="header-title">Patogeny {{ card.title }}</div>
          <div class="header-extra">Wybierz z listy patogen do zbadania</div>
        </div>

        <div class="content-items">
          <div *ngFor="let disease of card.diseases" class="item">
            <div class="item-top">
              <mat-checkbox
                [(ngModel)]="disease.selected"
                (change)="pathogenChecked(card.diseases)"
                color="primary"
              >
                {{ disease.name }}
              </mat-checkbox>
            </div>
            <div class="item-bottm">
              <mat-checkbox
                *ngFor="let symptom of disease.symptoms"
                [(ngModel)]="symptom.selected"
                [disabled]="!disease.selected"
                color="primary"
              >
                {{ symptom.name }}
              </mat-checkbox>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="card-bell">
        <img
          (click)="removeSelectionCard(i, card)"
          class="icon cp"
          src="assets/icons/close.svg"
          alt="icon"
        />
      </div>
    </div>
  </div>
</div>
