import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-annotations',
  templateUrl: './annotations.component.html',
  styleUrls: ['./annotations.component.scss'],
})
export class AnnotationsComponent {
  @Output() annotationsEmitt: EventEmitter<string> = new EventEmitter();
  public annotations = '';

  // TODO: this will come from translation service
  public text = {
    header: 'Adnotacje diagnosty',
    extra: 'Wpisz swoje uwagi dotyczące terapii lub pacjenta',
    placeholder: 'Adnotacje',
    save: 'Zapisz',
  };

  public save(): void {
    this.annotationsEmitt.emit(this.annotations);
  }
}
