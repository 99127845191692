import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MESSAGE_TYPE } from '../models';

@Component({
  selector: 'cl-info-bar[message][type]',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoBarComponent {
  @Input() message!: string;
  @Input() type!: MESSAGE_TYPE;
}
