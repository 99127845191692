import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_API_URL, VERSION } from 'src/app/shared/const';
import { ProductsFilter, Readings, Visit } from '../visit.models';

const VISIT_BASE_URL = `${BASE_API_URL}/visits/${VERSION}`;
const DISEASES_BASE_URL = `${BASE_API_URL}/diseases/${VERSION}`;

@Injectable()
export class VisitAPIService {
  constructor(private http: HttpClient) {}

  private getOptions(resType = 'json'): any {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const options: any = {
      headers: headers,
      responseType: resType as 'json',
      withCredentials: true,
    };

    if (resType === 'json') options['observe'] = 'response' as 'body';

    return options;
  }

  public getHealthFactors(): Observable<any> {
    return this.http.get(`${VISIT_BASE_URL}/health-factors`, this.getOptions());
  }

  public getBodySystems(): Observable<any> {
    return this.http.get(`${DISEASES_BASE_URL}/systems`, this.getOptions());
  }

  public getProducts(filter: ProductsFilter): Observable<any> { 
    const options = this.getOptions();    
    options['params'] = new HttpParams().set('filter', JSON.stringify(filter))

    return this.http.get(`${VISIT_BASE_URL}/products`, options);
  }

  public getDiseasesByBodySystem(id: number): Observable<ArrayBuffer> {
    const options = this.getOptions();
    options['params'] = new HttpParams().set('id', id);
    return this.http.get(DISEASES_BASE_URL, options);
  }

  public saveVisit(visit: Visit): Observable<any> {
    return this.http.post(`${VISIT_BASE_URL}/`, visit, this.getOptions());
  }

  public updateVisit(readings: Readings): Observable<any> {
    return this.http.patch(VISIT_BASE_URL, readings, this.getOptions());
  }


  public generateVisitPDF(id: string, 
                          fullPreview: boolean, 
                          deviceContract: string,
                          deviceModel: string): Observable<any> {
    
    const options = this.getOptions('blob');

    const params = { 'id': id,
                     'full_preview': fullPreview,
                     'device_contract': deviceContract,
                     'device_model': deviceModel
                    }

    return this.http.post(`${VISIT_BASE_URL}/pdf-file`, params, options);
  }

  public sendVisitPDF(id: string,                          
                      fullPreview: boolean, 
                      deviceContract: string,
                      deviceModel: string,
                      email?: string): Observable<any> {

    const params = { 'id': id,
                    'full_preview': fullPreview,
                    'device_contract': deviceContract,
                    'device_model': deviceModel,
                    'email': email
                  }

    return this.http.post(
      `${VISIT_BASE_URL}/pdf-email`,
      params,
      this.getOptions()
    );
  }

  public getVisitsHistory(patientId: string): Observable<any> {
    const options = this.getOptions('blob');
    options['params'] = new HttpParams().set('id', patientId);

    return this.http.get(`${VISIT_BASE_URL}/history`, options);
  }
}
