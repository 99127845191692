import { RecommendationsConfig } from '../../visit.models';

export const CONFIG: RecommendationsConfig = {
  setsAmount: {
    name: 'Ilość zestawów',
    values: [...Array(10).keys()].map((n: number) => (n + 1).toString()),
    selected: null,
    order: 1,
  },
  pathogenesPerSet: {
    name: 'Ilość patogenów na zestaw',
    values: [...Array(10).keys()].map((n: number) => (n + 1).toString()),
    selected: null,
    order: 2,
  },
  setTime: {
    name: 'Czas trwania zestawu',
    values: [...Array(90).keys()].map(
      (n: number) => (n + 1).toString() + ' dni'
    ),
    selected: null,
    order: 3,
  },
  frequencyTime: {
    name: 'Czas poj. częstotliwości',
    values: [...Array(60).keys()].map(
      (n: number) => (n + 1).toString() + ' min.'
    ),
    selected: null,
    order: 4,
  },
  powerRange: {
    name: 'Zakres mocy',
    values: ['10%/20%', '20%/40%', '30%/60%', '40%/80%', '50%/100%'],
    selected: null,
    order: 5,
  },
};

// TODO: this will come from TranslationService
export const TEXT = {
  protocolCreation: 'Tworzenie protokołu terapii rezonansowej',
  optionSelect:
    'Wybierz opcje poniżej aby przygotować indywidualny plan terapii',
  mainTitle: 'Lista obecnych patogenów',
  mainExtra: 'Wybierz zasady postępowania terapeutycznego',
};
