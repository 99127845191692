import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { VisitAPIService } from '../../services/visit-api.service';
import { EMPTY, Subscription, catchError, take, tap } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DeviceContract, PdfConfig } from '../../visit.models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACKBAR_CONFIG } from 'src/app/shared/const';
import { HttpErrorResponse } from '@angular/common/http';
import { MESSAGE_TYPE } from 'src/app/shared/models';

enum CONTROL {
  CURRENT = 'current',
  OTHER = 'other',
  FULL = 'full',
  SHORT = 'short',
  SALES = 'sales',
  RENTAL = 'rental',
  DEVICE1 = 'device1',
  DEVICE2 = 'device2',
}

@Component({
  selector: 'cl-printouts',
  templateUrl: './printouts.component.html',
  styleUrls: ['./printouts.component.scss'],
})
export class PrintoutsComponent implements OnInit,  OnDestroy {
  @Input() visitId: string = '';
  @Input() patientEmail: string = '';
  @Input() parentId: string = '';

  public isChildUser = false;
  public loadingEmailResponse = false;
  public control = CONTROL;
  public printForm: FormGroup = this.initPrintForm();
  public emailForm: FormGroup = this.initEmailForm();

  private subscriptions = new Subscription();

  constructor(private snackBar: MatSnackBar, private visitAPIService: VisitAPIService) {}

  ngOnInit(): void {
    this.isChildUser = Boolean(Number(this.parentId));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // TODO: this will come from translation service
  public text = {
    header: 'Skonfiguruj opcje wydruków',
    extra: 'Ustaw preferencje wydruku wyników dla pacjenta',
    cardPrint: {
      header: 'Preferencje wydruków',
      extra: 'Wybierz schemat wydruku wyników',
      fullText: 'Pelny wydruk',
      fullExtra: 'Zawiera wszystkie informacje wraz z interpretacją wyników',
      shortText: 'Wydruk skrócony',
      shortExtra: 'Zawiera podstawowe informacje bez interpretacji wyników',
      devicesText: 'Oferta urządzeń',
      devicesExtra: 'Zawiera dodatkowo ofertę na urządzenia terapeutyczne',
      buy: 'Zakup',
      rent: 'Wynajem',
      device1: "Urządzenie 1",
      device2: "Urządzenie 2",
      print: 'Drukuj',
    },
    cardEmail: {
      header: 'Wysyłka na maila',
      extra: 'Wybierz odpowiedni adres email',
      current: 'Wyślij na adres przypisany do konta pacjenta:',
      other: 'Wyślij na inny adres:',
      placeholder: 'przykładowy@email.com',
      emailError: 'Podaj prawidłowy email',
      send: 'Wyślij',
    },
  };

  public switchControls(control: string): void {
    switch (control) {
      case CONTROL.OTHER:
        this.emailForm.controls['current'].setValue(false);
        break;
      case CONTROL.CURRENT:
        this.emailForm.controls['other'].setValue(false);
        break;
      case CONTROL.FULL:
        this.printForm.controls['short'].setValue(false);
        break;
      case CONTROL.SHORT:
        this.printForm.controls['full'].setValue(false);
        break;
      case CONTROL.RENTAL:
        this.printForm.controls['sales'].setValue(false);
        break;
      case CONTROL.SALES:
        this.printForm.controls['rental'].setValue(false);
        break;    
      case CONTROL.DEVICE1:
        this.printForm.controls['device2'].setValue(false);
        break;     
      case CONTROL.DEVICE2:
        this.printForm.controls['device1'].setValue(false);
        break;    
    }
  }

  public getPDF(): void {

    const pdfConfig = this.getPdfConfig();

    this.subscriptions.add(
      this.visitAPIService
        .generateVisitPDF(this.visitId, pdfConfig.fullPreview, pdfConfig.contract, pdfConfig.device)
        .pipe(
          take(1),
          tap((data: Blob) => {
            const url = window.URL.createObjectURL(data);
            window.open(url, '_blank')
          }),
          catchError((e: HttpErrorResponse) => { 
            this.snackBar.open(e.message, '', { ...SNACKBAR_CONFIG, panelClass: [MESSAGE_TYPE.ERROR] }); 
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  public sendPDF(): void {

    this.loadingEmailResponse = true;

    const pdfConfig: PdfConfig = this.getPdfConfig();

    this.subscriptions.add(
      this.visitAPIService
        .sendVisitPDF(this.visitId, 
                      pdfConfig.fullPreview, 
                      pdfConfig.contract, 
                      pdfConfig.device,
                      this.emailForm.value['email'] || this.patientEmail)
        .pipe(
          take(1),
          tap((_resp) => this.loadingEmailResponse = false),
          tap((resp: { body: { message: string } }) => this.snackBar.open(resp.body.message, '', SNACKBAR_CONFIG)),
          catchError((e: HttpErrorResponse) => { 
            this.loadingEmailResponse = false;
            this.snackBar.open(e.message, '', { ...SNACKBAR_CONFIG, panelClass: [MESSAGE_TYPE.ERROR] }); 
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  public handleSelection(): void {}


  private getPdfConfig(): PdfConfig {
    const values = this.printForm.value;

    let contract: DeviceContract = DeviceContract.NONE;
    let device = '';
    if (values.devices) {
      contract = values.sales ? DeviceContract.SALES : DeviceContract.RENTAL
      device = values.device1 ? 'device1' : 'device2'
    }
    return { contract, device, fullPreview: values['full'] }
  }

  private initPrintForm(): FormGroup {
    return new FormGroup({
      full: new FormControl(true),
      short: new FormControl(''),
      devices: new FormControl(false),
      sales: new FormControl(false),
      rental: new FormControl(false),
      device1: new FormControl(false),
      device2: new FormControl(false),
    });
  }

  private initEmailForm(): FormGroup {
    return new FormGroup({
      current: new FormControl(true),
      other: new FormControl(''),
      email: new FormControl('', [Validators.email]),
    });
  }
}
