import { Injectable } from '@angular/core';
import { ConfigItem, Disease, RecommendationsConfig } from '../../visit.models';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable()
export class FormBuilderService {
  constructor(private fb: FormBuilder) {}

  public createRecommendationsForm(pathogens: Disease[], 
                                  existingForm: FormGroup, 
                                  config: RecommendationsConfig): FormGroup {
    let form: FormGroup = new FormGroup({});
    const diseaseFormArray: FormGroup[] = [];

    pathogens.forEach((p: Disease, index: number) => {

      const configDone = (Object.values(config).every((item: ConfigItem) => item.selected))
      const existingValue = existingForm.value.items?.find((item: any) =>  item.itemId === p.id) || null;

      const diseaseFG = this.fb.group({
        itemId: [p.id],
        itemIndex: [index],
        activitySelection: existingValue ? existingValue['activitySelection'] : [false],
        activityDataSource: existingValue ? existingValue['activityDataSource'] : [{ value: '', disabled: true }],
        freqenciesSelection: existingValue ? existingValue['freqenciesSelection'] : [false],
        freqenciesDataSource: existingValue ? existingValue['freqenciesDataSource'] : [{ value: '', disabled: true }],
        programSelection: existingValue ? existingValue['programSelection'] : [false],
        programDataSource: existingValue ? existingValue['programDataSource'] : [{ value: '', disabled: true }],
        setSelection: existingValue ? existingValue['setSelection'] : [{ value: false, disabled: !configDone }],
        setDataSource: existingValue ? [existingValue['setDataSource']] :  [{ value: '', disabled: true }],
      });
      diseaseFormArray.push(diseaseFG);
    });

    form = this.fb.group({
      items: this.fb.array(diseaseFormArray),
    });

    return form;
  }
}
