import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';

import { User } from 'src/app/auth/auth.models';
import { AgePipe } from '../../pipes/age.pipe';
import { TranslateService } from '@ngx-translate/core';
import { AVATAR_PATH } from 'src/app/auth/auth.const';
import { extractBase64ImgExtension } from '../../utils';

@Component({
  standalone: true,
  imports: [CommonModule, MatDatepickerModule, AgePipe],
  selector: 'cl-user-data[user]',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss']
})
export class UserDataComponent  implements OnInit {
  @Input() user!: User;
  @Input() canEditDate = false;

  public todaysDate: Date = new Date();
  public translations = { birthDate: '', age: ''};
  public avatarURL!: string;

  constructor(private translationService: TranslateService) {}

  ngOnInit(): void {
    this.translations.birthDate = this.translationService.instant('visit.birthDate');
    this.translations.age = this.translationService.instant('visit.age');
    this.setInitialBirthDay();
    this.setAvatarUrl();
  }

  public setBirthDay(e: MatDatepickerInputEvent<Date>): void {
    this.user.birthdateDisplayed = e.value as Date;
    this.user.birthdateDisplayed?.setHours(12);
  }

  private setInitialBirthDay(): void {
    this.user.birthdateDisplayed = this.user.birthdate ? new Date(this.user.birthdate) : this.setClearDate();
  }

  private setClearDate(): Date {
    const date = new Date();
    date.setUTCFullYear(1);
    date.setMonth(0);
    date.setDate(1);
    return date;
  }

  private setAvatarUrl(): void {
    if (this.user.avatar) {
      const imgFomat = extractBase64ImgExtension(this.user.avatar);
      this.avatarURL = `data:image/${imgFomat};base64,${this.user.avatar}`
    } else {
      this.avatarURL = AVATAR_PATH;
    }
  }
}