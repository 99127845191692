import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextFieldModule } from '@angular/cdk/text-field';

import { VisitViewComponent } from './visit-view/visit-view.component';
import { VisitRoutingModule } from './visit-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VisitTranslationsService } from './services/translations.service';
import { HealthFactorsTableComponent } from './components/health-factors-table/health-factors-table.component';
import { PathogensComponent } from './components/pathogens/pathogens.component';
import { RecommendationsComponent } from './components/recommendations/recommendations.component';
import { ProductsComponent } from './components/products/products.component';
import { AnnotationsComponent } from './components/annotations/annotations.component';
import { PrintoutsComponent } from './components/printouts/printouts.component';
import { SharedModule } from '../shared/shared.module';
import { ZipService } from '../shared/services/zip.service';

import { FormBuilderService } from './components/recommendations/form-builder.service';
import { OrderByPipe } from '../shared/pipes/order-by.pipe';
import { MatNativeDateModule } from '@angular/material/core';
import { CutFirstWordPipe } from '../shared/pipes/cut-first-word.pipe';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserDataComponent } from '../shared/components/user-data/user-data.component';
import { ModalService } from '../shared/services/modal.service';
import { ModalPDFComponent } from '../shared/components/modal-pdf.component';

@NgModule({
  declarations: [
    VisitViewComponent,
    HealthFactorsTableComponent,
    PathogensComponent,
    RecommendationsComponent,
    ProductsComponent,
    AnnotationsComponent,
    PrintoutsComponent,
    ModalPDFComponent,
    OrderByPipe,
    CutFirstWordPipe,
  ],
  providers: [
    VisitTranslationsService,
    ZipService,
    ModalService,
    FormBuilderService,
    MatNativeDateModule,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    VisitRoutingModule,
    DragDropModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatTableModule,
    MatSelectModule,
    MatButtonModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatNativeDateModule,
    TextFieldModule,
    MatSortModule,
    MatProgressSpinnerModule,
    SharedModule,
    UserDataComponent,
  ],
})
export class VisitModule {}
