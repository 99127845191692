import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwitchableItem } from '../shared/models';
import { AuthAPIService } from '../auth/services/auth-api.service';
import { Observable, Subscription, tap } from 'rxjs';
import { UserBasicData, UserRole } from '../auth/auth.models';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

  // TODO: this will come from translation service 
  public text = {
    title: 'Ustawienia',
    actions: {
      profile: "Moje dane",
      company: "Profil firmy"
    },
    cancel: "Anuluj",
    save: "Zapisz",
    content: {
      image: {
        upload: "Kliknij by wgrać",
        drop: "lub upuść plik",
        formats: "PNG, JPG lub JPEG (max 1MB)",
        errorSize: "Plik nie został wgrany. Maksymalny rozmiar pliku: 1MB",
        errorType: "Plik nie został wgrany. Dozwolony format pliku: JPG, JPEG, PNG",
      },
      profile: {
        title: "Moje dane",
        extra: "Zaktualizuj swoje dane",
        name: "Imię i nazwisko",
        email: "Adres email",
        phone: "Numer telefonu",        
        photoTitle: "Twoje zdjęcie profilowe",
        photoCaption: "Będzie widoczne w profilu",
        salutation: "Zwrot",
        salutationPlaceholder: "Wybierz zwrot",
        salutationOptions: {
          doc: "Lek",
          mr: "Pan",
          mrs: "Pani",
          naturopath: "Naturopata",
        },
        saved: 'Poprawnie zapisano zmiany dla użytkownika',
        savedAvatar: 'Poprawnie zapisano avatar dla użytkownika',
        saveError: 'Błąd zapisu danych użytkownika',
      },
      company: {
        title: "Profil firmy",
        extra: "Zaktualizuj swoje dane firmowe i logo firmy",
        name: "Nazwa Twojej firmy",
        nameCaption: "Będzie widoczna dla Twoich klientów i na wszystkich wydrukach",
        description: "Opis firmy",
        descriptionCaption: "Krótki tekst o Twojej firmie",
        logo: "Twoje logo",
        logoCaption: "Wgraj logo Twojej firmy w celu personalizacji panelu aplikacji",
        street: "Ulica i numer",
        city: "Miasto i kod pocztowy",
        country: "Kraj",
        left: "znaków pozostało",
        saved: 'Poprawnie zapisano zmiany dla firmy',
        savedLogo: 'Poprawnie zapisano logo dla firmy',
        saveError: 'Błąd zapisu dla firmy',
      }
    } 
  };

  private subscriptions = new Subscription();

  public tabs: SwitchableItem[] = [{ applied: true, text: this.text.actions.profile }];

  constructor(public authAPIService: AuthAPIService){}

  ngOnInit(): void {
    this.subscriptions.add(this.setButtons().subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public switchTab(index: number): void {
    this.tabs.forEach((item: SwitchableItem, idx: number) => item.applied = idx === index)
  }

  public setButtons(): Observable<UserBasicData | null> {
    return this.authAPIService.$activeUser.pipe(
      tap((u: UserBasicData | null) => {
        if (!u || !u.role) {return}
        if (u?.role === UserRole.SPECIALIST) {
          this.tabs.push({ applied: false, text: this.text.actions.company })
        } 
      })
    )
  }

}
