import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { HomeComponent } from './home.component';
import { HomeTranslationsService } from './services/translations.service';
import { CalendarAPIService } from '../shared/services/calendar-api.service';
import { SharedModule } from '../shared/shared.module';

import { PhonePipe } from '../shared/pipes/phone.pipe';
import { WeekComponent } from './components/week/week.component';

@NgModule({
  declarations: [HomeComponent, PhonePipe, WeekComponent ],
  providers: [HomeTranslationsService, CalendarAPIService],
  imports: [
    CommonModule, 
    FormsModule,
    ReactiveFormsModule,
    SharedModule, 
    MatButtonModule, 
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
  ],
})
export class HomeModule {}
