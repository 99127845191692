import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ProfileComponent } from './components/profile/profile.component';
import { CompanyComponent } from './components/company/company.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { i18CountriesPipe } from '../shared/pipes/countries.pipe';
import { SettingsService } from './settings.service';
import { DropzoneDirective } from '../shared/directives/dropzone.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    SettingsComponent, 
    ProfileComponent, 
    CompanyComponent,
    DropzoneDirective,
    i18CountriesPipe],
  providers: [SettingsService],  
  imports: [
    CommonModule, 
    SettingsRoutingModule, 
    SharedModule, 
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSnackBarModule,
  ]
})
export class SettingsModule { }
