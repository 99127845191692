import { Country } from "country-list-with-dial-code-and-flag";

export type TranslationSet = Record<string, string>;

export interface PDFFile {
  _data: any;
  date: Date;
  [key: string]: any;
}

export interface SwitchableItem {
  applied: boolean,
  text: string,
  [x: string]: unknown,
}

export interface i18Country extends Country {
  i18Name?: string,
  icon?: any,
}

export enum MESSAGE_TYPE {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'  
}

export enum VisitsViewRange {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  WEEK = 'week'
}