import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import {
  ConfigItem,
  DataSource,
  Disease,
  DiseaseCategory,
  DiseaseSubCategory,
  Frequency,
  Recommendation,
  RecommendationsConfig,
  SelectionCard,
  SetItem,
} from '../../visit.models';
import { CONFIG, TEXT } from './recommendations.const';
import { FormBuilderService } from './form-builder.service';
import { FormArray, FormGroup } from '@angular/forms';
// import { PATHOGENES_MOCK } from 'src/app/shared/const';

enum SECTION {
  ACTIVITY = 'activity',
  FREQUENCIES = 'frequencies',
  PROGRAMS = 'programs',
  SETS = 'sets',
}

@Component({
  selector: 'cl-recommendations[tabIndex]',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
})

export class RecommendationsComponent implements OnChanges {
  @Input() tabIndex!: number;
  @Input() pathogenesCards: Partial<SelectionCard>[] = [];
  @Output() recommendationsEmiter: EventEmitter<Recommendation[]> =
    new EventEmitter();

  public readonly Section = SECTION;
  public pathogens: Disease[] = [];
  public configActive = false;
  public recommendations: Recommendation[] = [];
  public config: RecommendationsConfig = structuredClone(CONFIG);
  public text = TEXT;
  public form: FormGroup = new FormGroup([]);
  public treatmentSets: SetItem[] = [];
  // public mockPTH = PATHOGENES_MOCK; // DELETE

  constructor(private formBuilderService: FormBuilderService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabIndex'].currentValue === 2) {      
      this.pathogens = this.filterPathogens(this.pathogenesCards);
      this.form = this.formBuilderService.createRecommendationsForm(
        this.pathogens, this.form, this.config
      );

    }
  }


  public get pathogensForms(): FormArray {
    return this.form.controls['items'] as FormArray;
  }

  public toggleConfig(): void {
    this.configActive = !this.configActive;
  }

  public sectionSelected(item: any, e: Event, index: number): void {
    const section: SECTION = (e.currentTarget as any)['id'];
    const programDataSource = (item as FormGroup).get('programDataSource');
    const freqenciesDataSource = (item as FormGroup).get(
      'freqenciesDataSource'
    );
    switch (section) {
      case SECTION.ACTIVITY:
        if ((item as FormGroup).get('activitySelection')?.value) {
          (item as FormGroup).get('activityDataSource')?.enable();
        } else {
          (item as FormGroup).get('activityDataSource')?.reset();
          (item as FormGroup).get('activityDataSource')?.disable();
        }
        break;
      case SECTION.FREQUENCIES:
        (item as FormGroup).get('freqenciesSelection')?.value
          ? freqenciesDataSource?.enable()
          : freqenciesDataSource?.disable();

        if (item.value['programSelection']) {
          (item as FormGroup).controls['programSelection'].reset();
          programDataSource?.reset();
          programDataSource?.disable();
        }
        break;

      case SECTION.PROGRAMS:
        (item as FormGroup).get('programSelection')?.value
          ? programDataSource?.enable()
          : programDataSource?.disable();

        if (item.value['freqenciesSelection']) {
          (item as FormGroup).controls['freqenciesSelection'].reset();
          this.deleteCategoryPath(index);
          freqenciesDataSource?.disable();
        }
        break;

      case SECTION.SETS:
        const setDataSource = (item as FormGroup).get('setDataSource');

        this.pathogens[index].selectedSet = this.pathogens[
          index
        ].selectedSet?.filter(
          (s: SetItem) => !setDataSource?.value.includes(s)
        );

        this.manageActiveSetItems(setDataSource?.value);

        if ((item as FormGroup).get('setSelection')?.value) {
          setDataSource?.enable();
        } else {
          setDataSource?.reset();
          setDataSource?.disable();
        }

        break;
      default:
        break;
    }
  }

  public programSelected(e: MatSelectChange, index: number, loadAllFrequencies = false): void {
    const frequencies = this.pathogens[index].frequencies.filter(
      (f: Frequency) => f.dataSourceId == e.value
    );
    const ds: DataSource | undefined = this.pathogens[index].dataSources.find(
      (d: DataSource) => d.id == e.value
    );

    this.pathogens[index].selectedDataSource = ds;
    this.pathogens[index].filteredFrequencies = frequencies;
    this.pathogens[index].categoryPath = this.getCategoryPath(e.value, index);

    if (loadAllFrequencies) this.pathogens[index].selectedFrequencies = frequencies;

    this.prepareRecommendations();
  }

  public frequencySelected(pathogenIndex: number, frequency: Frequency): void {
    const selected = this.pathogens[pathogenIndex].selectedFrequencies;
    const index = selected?.findIndex((f: Frequency) => f.id === frequency.id);

    index !== undefined && index > -1
      ? selected?.splice(index, 1)
      : selected?.push(frequency);

    this.prepareRecommendations();
  }

  public configSelected(e: MatSelectChange, item: ConfigItem): void {
    item.selected = e.value;

    if (
      this.config.pathogenesPerSet.selected &&
      this.config.setsAmount.selected
    ) {
      this.treatmentSets = [
        ...Array(Number(this.config.setsAmount.selected)).keys(),
      ].map((n: number) => {
        return {
          name: `Zestaw nr. ${(n + 1).toString()}`,
          active: true,
        };
      });
    }
    if (Object.values(this.config).every((item: ConfigItem) => item.selected)) {
      this.pathogensForms.controls.forEach((c: unknown) => {
        (c as FormGroup).get('setSelection')?.enable();
      });
    }
  }

  public setSelected(e: MatSelectChange, index: number): void {
    this.pathogens[index].selectedSet = e.value;
    this.manageActiveSetItems(e.value);
    this.prepareRecommendations();
  }

  public deleteCategoryPath(pathogenIndex: number): void {
    this.pathogens[pathogenIndex].selectedDataSource = null;
    this.pathogens[pathogenIndex].filteredFrequencies = [];
    this.pathogens[pathogenIndex].selectedFrequencies = [];
    (this.pathogensForms.controls[pathogenIndex] as FormGroup).controls[
      'freqenciesDataSource'
    ].reset();
    this.prepareRecommendations();
  }

  public activityInput(event: any, pathogenIndex: number): void {
    let val = Number(event.target.value);

    if (val > 100) {
      val = 100;
      this.pathogensForms.controls[pathogenIndex]
        .get('activityDataSource')
        ?.setValue(val);
    } else if (val < 1) {
      val = 1;
      this.pathogensForms.controls[pathogenIndex]
        .get('activityDataSource')
        ?.setValue(val);
    }

    this.pathogens[pathogenIndex].activity = val;
  }

  private manageActiveSetItems(sets: SetItem[]): void {
    sets &&
      sets.forEach((s: SetItem) => {
        const usage: SetItem[] = [];

        this.pathogens.forEach((d: Disease) => {
          if (d.selectedSet?.includes(s)) usage.push(s);
        });

        s.active = !(
          usage.length == Number(this.config.pathogenesPerSet.selected)
        );
      });
  }

  private filterPathogens(cards: Partial<SelectionCard>[]): Disease[] {
    const pathogens: Disease[] = [];
    cards.forEach((card: Partial<SelectionCard>) => {
      card.diseases?.forEach((d: Disease) => {
        if (d.selected) {
          d.selectedFrequencies = [];
          pathogens.push(d);
        }
      });
    });
    return pathogens;
  }

  private getCategoryPath(dataSourceId: number, pathogenIndex: number): string {
    const category = this.pathogens[pathogenIndex].categories.find(
      (c: DiseaseCategory) => c.data_source === dataSourceId
    );
    const subcategory = this.pathogens[pathogenIndex].subCategories.find(
      (s: DiseaseSubCategory) => s.category === category?.id
    );
    return `${category?.name} / ${subcategory?.name}`;
  }

  private prepareRecommendations(): void {
    this.recommendations = [];

    const config = this.clearConfig();

    const unassigned = this.pathogens.filter((d: Disease) => !d.selectedSet);
    if (unassigned.length) {
      this.recommendations.push(new Recommendation('', config, unassigned));
    }

    this.treatmentSets.forEach((set: SetItem) => {
      const pathogens = this.pathogens.filter((p: Disease) =>
        p.selectedSet?.some((s: SetItem) => s.name == set.name)
      );

      if (pathogens.length) {
        this.recommendations.push(
          new Recommendation(set.name, config, pathogens)
        );
      }
    });

    if (this.recommendations.length) {
      this.recommendationsEmiter.emit(this.recommendations);
    }
  }

  private clearConfig(): Partial<RecommendationsConfig> {
    const config = JSON.parse(JSON.stringify(this.config));
    for (const item in config) {
      for (const p in config[item as keyof RecommendationsConfig]) {
        delete config[item as keyof RecommendationsConfig].values;
      }
    }
    return config;
  }
}
