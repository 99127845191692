import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { TranslationSet } from '../../shared/models';

@Injectable()
export class HomeTranslationsService {
  constructor(private translationService: TranslateService) {}

  public getHomeTranslations(): Observable<TranslationSet> {
    const requiredTranslations = [
      'home.greetingTitle',
      'home.greetingExtraText',
      'home.control',
      'home.basic',
      'home.scheduled',
      'home.visitsNumber',
      'home.hour',
      'home.patient',
      'home.phone',
      'home.type',
      'home.action',
      'home.start',
      'home.today',
      'home.tomorrow',
      'home.week',
      'home.noVisits',
      'home.monday',
      'home.tuesday',
      'home.wednesday',
      'home.thursday',
      'home.friday',
      'home.saturday',
      "home.back",
      "home.forward"
    ];
    return this.translationService
      .get(requiredTranslations)
      .pipe(
        map((translations: TranslationSet) =>
          this.assignHomeTranslations(translations)
        )
      );
  }

  public assignHomeTranslations(translations: TranslationSet): TranslationSet {
    const text = {
      greetingTitle: translations['home.greetingTitle'],
      greetingExtraText: translations['home.greetingExtraText'],
      control: translations['home.control'],
      basic: translations['home.basic'],
      scheduled: translations['home.scheduled'],
      visitsNumber: translations['home.visitsNumber'],
      hour: translations['home.hour'],
      patient: translations['home.patient'],
      phone: translations['home.phone'],
      type: translations['home.type'],
      action: translations['home.action'],
      start: translations['home.start'],
      today: translations['home.today'],
      tomorrow: translations['home.tomorrow'],
      week: translations['home.week'],
      noVisits: translations['home.noVisits'],
      monday: translations['home.monday'],
      tuesday: translations['home.tuesday'],
      wednesday: translations['home.wednesday'],
      thursday: translations['home.thursday'],
      friday: translations['home.friday'],
      saturday: translations['home.saturday'],
      back: translations["home.back"],
      forward: translations["home.forward"]
    };
    return text;
  }
}
