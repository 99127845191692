<div class="form-container global-override-primary">
  <div class="header">
    <div class="title">{{translations.content.profile.title}}</div>
    <div class="extra">{{translations.content.profile.extra}}</div>
  </div>

  <form 
    class="form" 
    [formGroup]="form"
    (ngSubmit)="save()" >

    <div class="item">
      <div class="item-label">{{translations.content.profile.name}}</div>
      <div class="item-inputs">
        <input type="text" formControlName="firstName" class="input">
        <input type="text" formControlName="lastName" class="input">
      </div>
    </div>

    <div class="item">
      <div class="item-label">{{translations.content.profile.email}}</div>
      <div class="item-inputs">
          <input type="text" formControlName="email" class="input with-icon mail">
      </div>
    </div>

    <div class="item">
      <div class="item-label">{{translations.content.profile.phone}}</div>
      <div class="item-inputs">
        <input type="text" formControlName="phone" class="input with-icon phone">
      </div>
    </div>

    <div class="item">
      <div class="item-labels">
        <div class="item-label">{{translations.content.profile.photoTitle}}</div>
        <div class="item-caption">{{translations.content.profile.photoCaption}}</div>
      </div>
      <div class="item-inputs img-container">
        
        <input
          #fileInput 
          type="file" 
          (change)="setImageFromInput($event)" 
          accept="image/jpg, image/jpeg, image/png" 
          class="hide">

        <div *ngIf="avatarUrl" class="img-preview">
          <img class="img" [src]="avatarUrl"/>
        </div>
        <div 
          clDropzone 
          (fileDropped)="setImage($event)"
          (click)="fileInput.click()" 
          class="img-upload">
          <div class="upload-icon">
            <img src="assets/icons/upload.svg" alt="upload"/>
          </div>
          <div class="upload">
            <span class="upload-accent">{{translations.content.image.upload}}</span>
            {{translations.content.image.drop}}
          </div>
          <div class="upload">{{translations.content.image.formats}}</div>
          <p *ngIf="imageErrors.size" class="error">
            {{ translations.content.image.errorSize }}
          </p>
          <p *ngIf="imageErrors.type" class="error">
            {{ translations.content.image.errorType }}
          </p>
        </div>

      </div>
    </div>

    <div class="item">
      <div class="item-label">{{translations.content.profile.salutation}}</div>
      <div class="item-inputs">
        <mat-form-field appearance="fill" class="item-form-field">
          <mat-select
            placeholder="{{ translations.content.profile['salutationPlaceholder'] }}"
            formControlName="title"
          >
            <mat-option
              *ngFor="let item of translations.content.profile.salutationOptions | keyvalue"
              [value]="item.value"
              >{{ item.value }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="actions">
      <button (click)="cancel()" [disabled]="!form.valid" mat-button class="neutral">
        {{ translations.cancel}}
      </button>
      <button type="submit" [disabled]="!form.valid" mat-button class="btn">
        {{ translations.save }}
      </button>
    </div>

  </form>

</div>
