<div class="form-container global-override-primary">
  <div class="header">
    <div class="title">{{translations.content.company.title}}</div>
    <div class="extra">{{translations.content.company.extra}}</div>
  </div>

  <form 
    class="form" 
    [formGroup]="form"
    (ngSubmit)="save()">
    
    <div class="item">
      <div class="item-labels">
        <div class="item-label">{{translations.content.company.name}}</div>
        <div class="item-caption">{{translations.content.company.nameCaption}}</div>
      </div>
      <div class="item-inputs">
        <input type="text" formControlName="name" class="input">
      </div>
    </div>

    <div class="item">
      <div class="item-labels">
        <div class="item-label">{{translations.content.company.description}}</div>
        <div class="item-caption">{{translations.content.company.descriptionCaption}}</div>
      </div>
      
      <div class="item-inputs column">
        <textarea
          (input)="recalculateDescriptionLimit()"
          formControlName="description"
          [maxlength]="descriptionLimit"
          placeholder="{{ translations.content.company.descriptionCaption }}"
          class="textarea"
          matInput
        ></textarea>
        <div class="description-limit">{{descriptionLeft}} {{translations.content.company.left}} </div>
      </div>
    </div>

    <div class="item">
      <div class="item-labels">
        <div class="item-label">{{translations.content.company.logo}}</div>
        <div class="item-caption">{{translations.content.company.logoCaption}}</div>
      </div>
      <div class="item-inputs img-container">

        <input
          #fileInput 
          type="file" 
          (change)="setImageFromInput($event)" 
          accept="image/jpg, image/jpeg, image/png" 
          class="hide">

        <div *ngIf="logoUrl" class="img-preview">
          <img class="img" [src]="logoUrl"/>
        </div>
        <div 
          clDropzone 
          (fileDropped)="setImage($event)" 
          (click)="fileInput.click()" 
          class="img-upload">
          <div class="upload-icon">
            <img src="assets/icons/upload.svg" alt="upload"/>
          </div>
          <div class="upload">
            <span class="upload-accent">{{translations.content.image.upload}}</span>
            {{translations.content.image.drop}}
          </div>
          <div class="upload">{{translations.content.image.formats}}</div>
          <p *ngIf="imageErrors.size" class="error">
            {{ translations.content.image.errorSize }}
          </p>
          <p *ngIf="imageErrors.type" class="error">
            {{ translations.content.image.errorType }}
          </p>
        </div>

      </div>
    </div>

    <div class="item">
      <div class="item-label">{{translations.content.company.street}}</div>
      <div class="item-inputs">
        <input type="text" formControlName="street" class="input">
      </div>
    </div>
    
    <div class="item">
      <div class="item-label">{{translations.content.company.city}}</div>
      <div class="item-inputs">
        <input type="text" formControlName="city" class="input">
      </div>
    </div>

    <div class="item">
      <div class="item-label">{{translations.content.company.country}}</div>
      <div class="item-inputs">

        <mat-form-field appearance="fill" class="item-form-field">
          <mat-select
            #select
            placeholder="{{ translations.content.company.country }}"
            formControlName="country"
            (selectionChange)="setSelectedCountryFlag($event)"
          >
          <mat-select-trigger>
            <img
            [src]="selectedCountryFlag"
            alt="country-icon"
            style="height: 20px; width: 30px; margin-right: 10px; padding-top: 1px;"
            class="country-icon"
          />
            {{ select.value }}
          </mat-select-trigger>
            <mat-option
              *ngFor="let country of countries | i18country"
              [value]="country['i18Name']"
              style="display: flex; align-items: center;"
            >
              <img
                [src]="country['icon']"
                alt="country-icon"
                style="height: 30px; width: 30px; margin-right: 10px;"
                class="country-icon"
              />
              {{ country['i18Name'] }}
            </mat-option>
          </mat-select>
        </mat-form-field>        
      </div>
    </div>

    <div class="actions">
      <button type="submit" [disabled]="!form.valid" mat-button class="neutral">
        {{ translations.cancel}}
      </button>
      <button type="submit" [disabled]="!form.valid" mat-button class="btn">
        {{ translations.save }}
      </button>
    </div>


  </form>

</div>