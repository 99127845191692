<div class="other">
  <div *ngFor="let set of recommendations; let i = index" class="card">
    <div class="table-header">
      <div class="left">
        <div class="left-title">{{ set.setName }}</div>
      </div>
    </div>

    <table
      *ngIf="set.diseases"
      mat-table
      [dataSource]="set.diseases"
      matSort
      #table
      (matSortChange)="sortData($event, set.diseases, table)"
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="pathogen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ text.table.name }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="activity">
        <th class="center" mat-header-cell *matHeaderCellDef>
          {{ text.table.activity }}
        </th>
        <td class="center" mat-cell *matCellDef="let element">
          {{ element.activity }} %
        </td>
      </ng-container>
      <ng-container matColumnDef="bodySystem">
        <th mat-header-cell *matHeaderCellDef>{{ text.table.bodySystem }}</th>
        <td mat-cell *matCellDef="let element, let index = index">
          <mat-form-field appearance="outline">
            <mat-select
              multiple
              (selectionChange)="selectedBodySystem($event, element)"
              [(ngModel)]="set.diseases[index].selectedBodySystems"
            >
              <mat-option
                *ngFor="let value of element.bodySystems"
                [value]="value"
                >{{ value.name | cutFirstWord }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="sympthoms">
        <th class="left" mat-header-cell *matHeaderCellDef>
          {{ text.table.symtoms }}
        </th>
        <td class="left" mat-cell *matCellDef="let element">
          <div class="symptoms">
            <ng-container *ngFor="let item of element.symptoms">
              <cl-tag
                *ngIf="item.selected"
                [text]="item.name"
              ></cl-tag>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="pathogenColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: pathogenColumns"></tr>
    </table>
    <div class="products-header">
      {{ text.table.products }}
    </div>

    <table
      *ngIf="set.products.length"
      mat-table
      [dataSource]="set.products"
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef>
          {{ text.table.productName }}
        </th>
        <td mat-cell *matCellDef="let element">
          <input [(ngModel)]="element.selected" type="checkbox" class="checkbox" />
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>
          {{ text.table.productDesc }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="productColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: productColumns"></tr>
    </table>
    <div class="actions">
      <button (click)="applyForAll(i)" class="btn">
        {{ text.applyForAll }}
      </button>
    </div>
  </div>
</div>
