<ng-container *ngIf="loggedIn">
  <div class="sidebar">
    <div class="section">
      <div *ngIf="logoPath" class="container">
        <img [src]="logoPath" alt="logo" class="logo" />
      </div>
      <button (click)="goHome()" #selectable class="container">
        <img   
          class="icon" 
          src="assets/icons/home.svg" 
          alt="home" />
      </button>
      <button (click)="getMyPatients()" #selectable class="container">
        <img
          class="icon"
          src="assets/icons/people.svg"
          alt="home"
        />
      </button>
    </div>

    <div class="section">
      <button (click)="getHelp()" #selectable class="container">
        <img
          class="icon"
          src="assets/icons/help.svg"
          alt="home"
        />
      </button>
      <button (click)="getSettings()" #selectable class="container">
        <img
          class="icon"
          src="assets/icons/settings.svg"
          alt="home"
        />
      </button>
      <button (click)="logout()" class="container">
        <img
          class="icon"
          src="assets/icons/logout.svg"
          alt="logout"
        />
      </button>
      <div *ngIf="avatarPath" class="container">
        <img
          class="avatar"
          [src]="avatarPath"
          alt="avatar"
        />
      </div>
    </div>
  </div>
</ng-container>
