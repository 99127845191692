<div *ngIf="healthFactors && translations">
  <table mat-table [dataSource]="healthFactors" class="mat-elevation-z8">
    <ng-container matColumnDef="param">
      <th mat-header-cell *matHeaderCellDef>{{ translations["param"] }}</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="result">
      <th class="w20" mat-header-cell *matHeaderCellDef>
        {{ translations["result"] }}
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="outline">
          <mat-select
            (selectionChange)="selected($event, element)"
            placeholder="{{ translations['select'] }}"
          >
            <mat-option
              *ngFor="let value of element.allowedValues"
              [value]="value"
              >{{ value }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <tr
      class="header"
      mat-header-row
      *matHeaderRowDef="factorColumns; sticky: true"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: factorColumns"></tr>
  </table>
</div>
