import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Subscription, tap } from 'rxjs';
import { BodySystem, Disease, SelectionCard } from '../../visit.models';
import { MatSelectChange } from '@angular/material/select';
import { VisitAPIService } from '../../services/visit-api.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'cl-pathogens',
  templateUrl: './pathogens.component.html',
  styleUrls: ['./pathogens.component.scss'],
})
export class PathogensComponent implements OnDestroy {
  @Input() bodySystems: BodySystem[] = [];
  @Input() selectionCards: SelectionCard[] = [];
  @Output() pathogenChange: EventEmitter<void> = new EventEmitter();
  public pathogenes: Disease[] = [];
  public bodySystemsSelectDisabled = false;
  public pathogenesSelectDisabled = false;
  public bodySystemsInitial = '';
  public pathogensInitial = '';
  private subscriptions = new Subscription();

  constructor(private visitAPIService: VisitAPIService) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public drop(e: CdkDragDrop<SelectionCard[]>): void {
    moveItemInArray(e.container.data, e.previousIndex, e.currentIndex);
  }

  public selectedSystem(e: MatSelectChange): void {
    const selectedIndex = this.bodySystems.findIndex(
      (s: BodySystem) => s.name == e.value
    );
    this.subscriptions.add(
      this.visitAPIService
        .getDiseasesByBodySystem(this.bodySystems[selectedIndex].id)
        .pipe(
          tap((resp: any) => {
            this.pathogenes = resp.body;
            this.pathogenes.forEach((d: Disease) => {
              d.symptoms = (d.symptoms as unknown as string)
                .split(',')
                .map((symptom: string) => {
                  return { name: symptom, selected: false };
                });
              d.selected = false;
            });
            const card: SelectionCard = {
              title: e.value,
              diseases: this.pathogenes,
            };
            this.selectionCards.push(card);
            this.bodySystems[selectedIndex].selected = true;
            this.bodySystemsSelectDisabled = this.bodySystems.every(
              (s: BodySystem) => s.selected
            );
          })
        )
        .subscribe()
    );
  }

  public removeSelectionCard(index: number, card: SelectionCard): void {
    this.selectionCards.splice(index, 1);

    const bodySystemIndex = this.bodySystems.findIndex(
      (s: BodySystem) => s.name == card.title
    );
    this.bodySystems[bodySystemIndex].selected = false;
    this.bodySystemsSelectDisabled = this.bodySystems.every(
      (s: BodySystem) => s.selected
    );
    this.bodySystemsInitial = '';
  }

  public pathogenChecked(currentCardDiseases: Disease[]): void {
    this.pathogensInitial = '';
    this.pathogenesSelectDisabled = currentCardDiseases.every(
      (d: Disease) => d.selected
    );
    this.pathogenChange.emit();
  }

  public selectedPathogen(e: MatSelectChange): void {
    const selectedCard = this.selectionCards[this.selectionCards.length - 1];

    const selectedIndex = selectedCard.diseases.findIndex(
      (d: Disease) => d.name === e.value
    );

    selectedCard.diseases[selectedIndex].selected = true;
    this.pathogenChange.emit();
  }
}
