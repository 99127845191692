<div class="recommendations global-override-primary">
  <div class="activation-bar" [class.activated]="configActive">
    <div class="header">
      <input
        type="checkbox"
        (change)="toggleConfig()"
        class="checkbox"
      />
      <div class="text">
        <div class="text-header">{{ text.protocolCreation }}</div>
        <div class="text-extra">{{ text.optionSelect }}</div>
      </div>
    </div>

    <!-- CONFIG  -->

    <div *ngIf="configActive" class="selects">
      <div *ngFor="let item of config | keyvalue | orderBy : 'order'">
        <mat-label class="label"> {{ item.value.name }} </mat-label>
        <mat-form-field class="select" appearance="outline">
          <mat-select
            (selectionChange)="configSelected($event, item.value)"
            [(ngModel)]="item.value.selected"
          >
            <mat-option
              *ngFor="let value of item.value.values"
              [value]="value"
              >{{ value }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngIf="configActive" class="pathogens">
    <div class="header">
      <div class="header-text">{{ text.mainTitle }}</div>
      <div class="header-extra">{{ text.mainExtra }}</div>
    </div>

    <!-- FORM - PATHOGENS  -->
    <form [formGroup]="form">
      <ng-container formArrayName="items">
        <div
          *ngFor="let item of pathogensForms.controls; let i = index"
          class="card"
        >
          <div [formGroupName]="i" class="container">
            <div class="name">{{ pathogens[i].name }}</div>
            <div class="controls">
              <!-- ACTIVITY  -->
              <div class="control">
                <div class="check">
                  <input
                    formControlName="activitySelection"
                    id="{{ Section.ACTIVITY }}"
                    type="checkbox"
                    (change)="sectionSelected(item, $event, i)"
                    class="control-checkbox"
                  />
                  <div class="label">
                    <label for="{{ Section.ACTIVITY }}"
                      >Stopień aktywności</label
                    >
                  </div>
                </div>
                <div class="input-container">
                  <input
                    matInput
                    (change)="activityInput($event, i)"
                    formControlName="activityDataSource"
                    type="number"
                    class="input"
                    max="100"
                    placeholder="Wartość od 1 do 100"
                  />
                  <div class="test">%</div>
                </div>
              </div>

              <!-- FREQUENCIES  -->
              <div class="control">
                <div class="check">
                  <input
                    formControlName="freqenciesSelection"
                    id="{{ Section.FREQUENCIES }}"
                    type="checkbox"
                    (change)="sectionSelected(item, $event, i)"
                    class="control-checkbox"
                  />
                  <div class="label">
                    <label for="{{ Section.FREQUENCIES }}"
                      >Dobór częstotliwości</label
                    >
                  </div>
                </div>
                <mat-form-field class="select" appearance="outline">
                  <mat-label> Dodaj listę częstotliwości </mat-label>
                  <mat-select
                    (selectionChange)="programSelected($event, i)"
                    formControlName="freqenciesDataSource"
                  >
                    <mat-option
                      *ngFor="let dataSource of pathogens[i].dataSources"
                      [value]="dataSource.id"
                      >{{ dataSource.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="
                    pathogens[i].selectedDataSource &&
                    item.value['freqenciesSelection']
                  "
                  class="selected"
                >
                  {{ pathogens[i].selectedDataSource?.name }}
                  <img
                    [src]="'assets/icons/arrow-right-pure.svg'"
                    alt="icon-arrow-right"
                    class="icon-left"
                  />
                  <img
                    matTooltip="{{ pathogens[i].categoryPath }}"
                    [matTooltipPosition]="'above'"
                    [src]="'assets/icons/dots.svg'"
                    alt="icon-more"
                  />
                  <div
                    (click)="deleteCategoryPath(i)"
                    class="ico-frame"
                  >
                    <img [src]="'assets/icons/trash.svg'" alt="icon-trash" />
                  </div>
                </div>
              </div>

              <!-- PROGRAMS  -->
              <div class="control">
                <div class="check">
                  <input
                    formControlName="programSelection"
                    id="{{ Section.PROGRAMS }}"
                    type="checkbox"
                    (change)="sectionSelected(item, $event, i)"
                    class="control-checkbox"
                  />
                  <div class="label">
                    <label for="{{ Section.PROGRAMS }}"
                      >Wybór gotowego programu</label
                    >
                  </div>
                </div>
                <mat-form-field class="select" appearance="outline">
                  <mat-label> Wybierz program do terapii </mat-label>
                  <mat-select
                    (selectionChange)="programSelected($event, i, true)"
                    formControlName="programDataSource"
                  >
                    <mat-option
                      *ngFor="let dataSource of pathogens[i].dataSources"
                      [value]="dataSource.id"
                      >{{ dataSource.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- SETS  -->
              <div class="control">
                <div class="check">
                  <input
                    formControlName="setSelection"
                    id="{{ Section.SETS }}"
                    type="checkbox"
                    (change)="sectionSelected(item, $event, i)"
                    class="control-checkbox"
                  />
                  <div class="label">
                    <label for="{{ Section.SETS }}"
                      >Przynależność do zestawu</label
                    >
                  </div>
                </div>
                <mat-form-field class="select" appearance="outline">
                  <mat-label> Wybierz zestaw </mat-label>
                  <mat-select
                    multiple
                    (selectionChange)="setSelected($event, i)"
                    formControlName="setDataSource"
                  >
                    <mat-option
                      *ngFor="let set of treatmentSets"
                      [value]="set"
                      [disabled]="!set.active"
                      >{{ set.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <!-- FREQUENCY SELECTION  -->
          <div
            *ngIf="
              item.value['freqenciesSelection'] &&
              pathogens[i].filteredFrequencies?.length
            "
            class="freqencies"
          >
            <div class="freqencies-box">
              <div
                *ngFor="
                  let frequency of pathogens[i].filteredFrequencies;
                  let indx = index
                "
                class="tile"
              >
                <label for="frequency-{{ indx }}">
                  <input
                    id="frequency-{{ indx }}"
                    type="checkbox"
                    class="frequency-checkbox"
                    (change)="frequencySelected(i, frequency)"
                  />
                  {{ frequency.name }} Hz</label
                >
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
</div>
