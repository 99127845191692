import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslationSet } from 'src/app/shared/models';

import { HealthFactor } from '../../visit.models';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'cl-health-factors-table',
  templateUrl: './health-factors-table.component.html',
  styleUrls: ['./health-factors-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthFactorsTableComponent {
  @Input() healthFactors!: HealthFactor[];
  @Input() translations?: TranslationSet;
  public factorColumns = ['param', 'result'];

  public selected(e: MatSelectChange, factor: HealthFactor): void {
    factor.selectedValue = e.value;
  }
}
