import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VisitAPIService } from './services/visit-api.service';
import { VisitViewComponent } from './visit-view/visit-view.component';

const routes: Routes = [{ path: '', component: VisitViewComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [VisitAPIService],
  exports: [RouterModule],
})
export class VisitRoutingModule {}
