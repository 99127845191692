import { inject } from '@angular/core';
import { map, tap } from 'rxjs';
import { UserBasicData } from '../auth.models';
import { AuthAPIService } from '../services/auth-api.service';
import { Router } from '@angular/router';

export const AuthGuard = () => {
  const authAPIService = inject(AuthAPIService);
  const router = inject(Router);

  return authAPIService
    .getActiveUser()
    .pipe(
      map((user: UserBasicData | boolean) => Boolean(user)),
      tap((user: UserBasicData | boolean) => { if (!user) { router.navigateByUrl('auth/login')}})
      );
};