<div class="printouts">
  <div class="header">
    <div class="header-title">{{ text.header }}</div>
    <div class="header-extra">{{ text.extra }}</div>
  </div>

  <form [formGroup]="printForm" class="card">
    <div class="card-header">
      <div class="header-title">{{ text.cardPrint.header }}</div>
      <div class="header-extra">{{ text.cardPrint.extra }}</div>
    </div>
    <div class="card-controls">
      <label class="label">
        <input
          (change)="switchControls(control.FULL)"
          formControlName="full"
          type="checkbox"
          class="checkbox"
        />
        <div>
          <div class="label-header">{{ text.cardPrint.fullText }}</div>
          <div class="label-extra">{{ text.cardPrint.fullExtra }}</div>
        </div>
      </label>
      <label class="label">
        <input
          (change)="switchControls(control.SHORT)"
          formControlName="short"
          type="checkbox"
          class="checkbox"
        />
        <div>
          <div class="label-header">{{ text.cardPrint.shortText }}</div>
          <div class="label-extra">{{ text.cardPrint.shortExtra }}</div>
        </div>
      </label>

      <label class="label">
        <input formControlName="devices" type="checkbox" class="checkbox"/>
        <div>
          <div class="label-header">{{ text.cardPrint.devicesText }}</div>
          <div class="label-extra">{{ text.cardPrint.devicesExtra }}</div>
        </div>
      </label>

      <div *ngIf="printForm.controls['devices'].value" class="devices">
        <div class="devices-options">
          <label class="devices-label">
            <input 
              (change)="switchControls(control.SALES)" 
              formControlName="sales"
              type="checkbox" 
              class="checkbox" />
            {{ text.cardPrint.buy }}
          </label>
          <label class="devices-label">
            <input 
              (change)="switchControls(control.RENTAL)" 
              formControlName="rental" 
              type="checkbox" 
              class="checkbox" />
            {{ text.cardPrint.rent }}
          </label>
        </div>

        <div class="devices-list">
          <label class="devices-label">
            <input 
              (change)="switchControls(control.DEVICE1)" 
              formControlName="device1" 
              type="checkbox" 
              class="checkbox" 
            />
            {{ text.cardPrint.device1 }}
          </label>
          <label class="devices-label">
            <input 
              (change)="switchControls(control.DEVICE2)" 
              formControlName="device2" 
              type="checkbox" 
              class="checkbox" 
            />
            {{ text.cardPrint.device2 }}
          </label>
        </div>
      </div>

    </div>
    <div class="card-btn">
      <button
        (click)="getPDF()"
        [disabled]="
          !printForm.controls['full'].value &&
          !printForm.controls['short'].value
        "
        class="btn"
      >
        {{ text.cardPrint.print }}
      </button>
    </div>
  </form>

  <form [formGroup]="emailForm" class="card mh">
    <div class="card-header">
      <div class="header-title">{{ text.cardEmail.header }}</div>
      <div class="header-extra">{{ text.cardEmail.extra }}</div>
    </div>

    <div *ngIf="!loadingEmailResponse; else loader" class="card-controls">
      <label *ngIf="!isChildUser" class="label">
        <input
          (change)="switchControls(control.CURRENT)"
          formControlName="current"
          type="checkbox"
          class="checkbox"
        />
        <div>
          <div class="label-header">{{ text.cardEmail.current }}</div>
          <div class="label-extra">{{ patientEmail }}</div>
        </div>
      </label>
      <label class="label">
        <input
          (change)="switchControls(control.OTHER)"
          formControlName="other"
          type="checkbox"
          class="checkbox"
        />
        <div>
          <div class="label-header">{{ text.cardEmail.other }}</div>
          <input
            formControlName="email"
            [readonly]="!emailForm.controls['other'].value"
            type="email"
            class="input"
            [placeholder]="text['cardEmail']['placeholder']"
          />
          <p
            *ngIf="
              !emailForm.controls['email'].valid &&
              emailForm.controls['email'].touched
            "
            class="error"
          >
            {{ text.cardEmail.emailError }}
          </p>
        </div>
      </label>
    </div>
    <div class="card-btn">
      <button
        (click)="sendPDF()"
        [disabled]="
          (!emailForm.controls['other'].value &&
            !emailForm.controls['current'].value) ||
          (emailForm.controls['other'].value &&
            !emailForm.controls['email'].valid)
        "
        class="btn"
      >
        {{ text.cardEmail.send }}
      </button>
    </div>
  </form>

  <ng-template #loader><mat-spinner></mat-spinner></ng-template>
</div>
