import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PDFFile } from 'src/app/shared/models';

@Component({
  selector: 'cl-modal-pdf',
  template: `<iframe
    [src]="pdfURL"
    width="100%"
    height="100%"
    [frameBorder]="0"
  ></iframe>`,
})
export class ModalPDFComponent implements OnInit {
  pdfURL: SafeUrl = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PDFFile,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const PDFData: Uint8Array = this.data._data.compressedContent;
    const PDFBlob: Blob = new Blob([PDFData], { type: 'application/pdf' });
    this.pdfURL = this.domSanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(PDFBlob)
    );
  }
}
