import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoActionComponent } from './components/info-action/info-action.component';
import { InfoActionCodeComponent } from './components/info-action-code/info-action-code.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonsBarComponent } from './components/buttons-bar/buttons-bar.component';
import { TagComponent } from './components/tag/tag.component';
import { ButtonsSwitchComponent } from './components/buttons-switch/buttons-switch.component';
import { InfoBarComponent } from './info-bar/info-bar.component';
import { SlideOutInfoComponent } from './components/slide-out-info/slide-out-info.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [
    InfoActionComponent,
    InfoActionCodeComponent,
    ButtonsBarComponent,
    TagComponent,
    ButtonsSwitchComponent,
    InfoBarComponent,
    InfoBarComponent,
    SlideOutInfoComponent,
  ],
  exports: [
    InfoActionComponent,
    InfoActionCodeComponent,
    ButtonsBarComponent,
    ButtonsSwitchComponent,
    TagComponent,
    InfoBarComponent,
    SlideOutInfoComponent,
  ],
})
export class SharedModule {}
