import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, of, tap } from 'rxjs';
import { BASE_API_URL, VERSION } from 'src/app/shared/const';
import { Code, LoginData, User, UserBasicData, UserData } from '../auth.models';
import { Clinic } from 'src/app/settings/settings.model';

const URL = `${BASE_API_URL}/accounts/${VERSION}`;

@Injectable()
export class AuthAPIService {
  private activeUser = new BehaviorSubject<UserBasicData | null>(null);
  public $activeUser = this.activeUser.asObservable();

  public $avatar = new BehaviorSubject<Blob | null>(null);
  public $logo = new BehaviorSubject<Blob | null>(null);


  constructor(private http: HttpClient) {}

  public setActiveUser(user: UserBasicData | null): void {
    this.activeUser.next(user);
  }

  public getActiveUser(): Observable<UserBasicData | boolean > {
    const options = this.getOptions();
    return this.http
      .get<UserBasicData>(`${URL}/active-user`, options)
      .pipe(
        tap((user: UserBasicData) => this.setActiveUser(user)),
        catchError((_err) => of(false))
        );
  }

  public updateActiveUser(profile: UserBasicData): Observable<UserBasicData> {
    return this.http.patch<UserBasicData>(`${URL}/active-user`, profile, this.getOptions());
  }

  public getAciveUserCompany(): Observable<Clinic> {
    return this.http.get<Clinic>(`${URL}/active-company`, this.getOptions());
  }

  public updateActiveUserCompany(company: Clinic): Observable<Clinic> {
    return this.http.patch<Clinic>(`${URL}/active-company`, company, this.getOptions())
  }

  public getAvatar(): Observable<Blob> {
    const headers = new HttpHeaders({ 
      'content-type': 'application/json',
      'accept': 'image/jpeg'
    })
    const options = this.getOptions(headers);
    options['responseType'] = 'blob' as 'json';

    return this.http.get<Blob>(`${URL}/avatar`, options)
      .pipe(tap((avatar: Blob) => this.$avatar.next(avatar)))
  }

  public saveAvatar(avatar: FormData): Observable<Object> {
    const headers = new HttpHeaders({ "accept": "application/json" })

    return this.http.post<Object>(
      `${URL}/avatar`, 
      avatar,
      this.getOptions(headers));
  }

  public getLogo(): Observable<Blob> {
    const headers = new HttpHeaders({ 
      'content-type': 'application/json',
      'accept': 'image/jpeg'
    })
    const options = this.getOptions(headers);
    options['responseType'] = 'blob' as 'json';

    return this.http.get<Blob>(`${URL}/logo`, options)
      .pipe(tap((logo: Blob) => this.$logo.next(logo)))
  }

  public saveLogo(logo: FormData): Observable<Object> {
    const headers = new HttpHeaders({ "accept": "application/json" })

    return this.http.post<Object>(
      `${URL}/logo`, 
      logo,
      this.getOptions(headers));
  }

  public sendResetLink(email: string): Observable<Object> {
    return this.http.post<Object>(`${URL}/reset-link`, {email}, this.getOptions())
  }

  public resetPassword(encodedUserId: string, resetToken: string, password: string): Observable<Object> {
    return this.http.post<Object>(`${URL}/reset-password`, 
                                  {id: encodedUserId, token: resetToken, password}, 
                                  this.getOptions())
  }

  public login(data: LoginData): Observable<UserBasicData> {
    return this.http.post<UserBasicData>(`${URL}/login`, data, this.getOptions());
  }

  public logout(): Observable<Object> {
    return this.http.post<Object>(`${URL}/logout`, null, { withCredentials: true });
  }

  public getAccountData(id: string): Observable<User> {
    const options = this.getOptions();
    options['params'] = new HttpParams().set('id', id);
    return this.http.get<User>(`${URL}/account`, options);
  }

  public resendActivationCode(user: UserData): Observable<Code> {
    return this.http.patch<Code>(`${URL}/code`, user, this.getOptions());
  }

  public registerAccountData(user: UserData): Observable<Code> {
    return this.http.patch<Code>(`${URL}/register`, user, this.getOptions());
  }

  public updateAccountData(user: UserData): Observable<User> {
    return this.http.patch<User>(`${URL}/account`, user, this.getOptions());
  }

  private getOptions(customHeaders?: HttpHeaders): Record<string, unknown> {
    const headers = customHeaders || new HttpHeaders().set('content-type', 'application/json');
    const options = { headers: headers, withCredentials: true, params: {} };
    return options;
  }
}
