import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { TranslationSet } from 'src/app/shared/models';

@Injectable()
export class VisitTranslationsService {
  constructor(private translationService: TranslateService) {}

  public getTranslations(): Observable<Record<string, TranslationSet>> {
    return this.translationService
      .get([
        'visit.back',
        'visit.birthDate',
        'visit.age',
        'visit.historyPreview',
        'visit.visitBasic',
        'visit.visitControl',
        'visit.cancell',
        'visit.save',
        'visit.botttomBack',
        'visit.botttomNext',
        'visit.generalParams',
        'visit.pathogenes',
        'visit.recommendations',
        'visit.recommendationsOther',
        'visit.annotations',
        'visit.printouts',
        'visit.params.param',
        'visit.params.result',
        'visit.params.select',
        'visit.success',
        'visit.error'
      ])
      .pipe(
        map((translations: TranslationSet) => {
          const visit = {
            back: translations['visit.back'],
            birthDate: translations['visit.birthDate'],
            age: translations['visit.age'],
            historyPreview: translations['visit.historyPreview'],
            visitBasic: translations['visit.visitBasic'],
            visitControl: translations['visit.visitControl'],
            cancell: translations['visit.cancell'],
            save: translations['visit.save'],
            botttomBack: translations['visit.botttomBack'],
            botttomNext: translations['visit.botttomNext'],
            generalParams: translations['visit.generalParams'],
            pathogenes: translations['visit.pathogenes'],
            recommendations: translations['visit.recommendations'],
            recommendationsOther: translations['visit.recommendationsOther'],
            annotations: translations['visit.annotations'],
            printouts: translations['visit.printouts'],
            success: translations['visit.success'],
            error: translations['visit.error'],
          };
          const params = {
            param: translations['visit.params.param'],
            result: translations['visit.params.result'],
            select: translations['visit.params.select'],
          };
          return { visit, params };
        })
      );
  }

  // TODO: do we need this ???
  // public updateTranslations(): Observable<TranslationSet> {
  //   return this.translationService.onLangChange.pipe(
  //     map((update: LangChangeEvent) => {
  //       const text = {
  //         newVisit: update.translations.visit['newVisit'],
  //         selectDisease: update.translations.visit['selectDisease'],
  //         save: update.translations.visit['save'],
  //       };
  //       return text;
  //     })
  //   );
  // }

  public changeLanguage(lang: string) {
    this.translationService.use(lang);
  }
}
