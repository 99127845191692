export const unloadData = (data: unknown): unknown =>
  JSON.parse(JSON.stringify(data));

export const extractBase64ImgExtension = (base64: string): string => {
  const codeChar = base64.charAt(0);
  let result = '';
  switch (codeChar) {
    case '/':
      result = 'jpg'
      break;
    case 'i':
      result = 'png'
      break;
    case 'R':
      result = 'gif'
      break;
    case 'U':
      result = 'webp'
      break;
  
    default:
      break;
  }
  return result;
}