import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SwitchableItem } from '../../models';

@Component({
  selector: 'cl-buttons-switch',
  templateUrl: './buttons-switch.component.html',
  styleUrls: ['./buttons-switch.component.scss']
})

export class ButtonsSwitchComponent implements OnInit {
  @Input() items: SwitchableItem[] = [];
  @Output() selectedButton: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit(): void {
    if ( !this.items.some((item: SwitchableItem) => item.applied ) ) {this.items[0].applied = true}
  }

  public selectSwitchItem(index: number): void {
    this.selectedButton.emit(index);
  }
}
