<div *ngIf="translations" class="week">
  <table
    mat-table 
    [dataSource]="hourlyMatrix" 
    class="mat-elevation-z8">

    <ng-container matColumnDef="hour">
      <th mat-header-cell *matHeaderCellDef>{{ translations['hour']}}</th>
      <td mat-cell *matCellDef="let element" class="lighter-text">{{ element.hour }}</td>
    </ng-container>

    <ng-container matColumnDef="monday">
      <th mat-header-cell *matHeaderCellDef>
        {{ translations['monday'] | titlecase }}
        <span> {{daysMatrix[0] | slice : 5 }} </span>
      </th>
      <td mat-cell *matCellDef="let element" [class.empty]="!element.days[0]">
        <div class="flex">
          <div 
            *ngIf="element?.days[0]?.type" 
            class="type"
            [ngClass]="element?.days[0]?.type == VisitTypes.BASIC ? 'green' : 'orange'"> 
            {{ translations[element?.days[0]?.type][0] | uppercase }} 
          </div>
          {{ element.days[0]?.customer.full_name }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="tuesday">
      <th mat-header-cell *matHeaderCellDef>
        {{ translations['tuesday'] | titlecase }}
        <span> {{daysMatrix[1] | slice : 5 }} </span>
      </th>
      <td mat-cell *matCellDef="let element" [class.empty]="!element.days[1]">
        <div class="flex">
          <div 
            *ngIf="element?.days[1]?.type" 
            class="type"
            [ngClass]="element?.days[1]?.type == VisitTypes.BASIC ? 'green' : 'orange'"> 
            {{ translations[element?.days[1]?.type][0] | uppercase }} 
          </div>
          {{ element.days[1]?.customer.full_name }}
        </div>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="wednesday">
      <th mat-header-cell *matHeaderCellDef>
        {{ translations['wednesday'] | titlecase }}
        <span> {{daysMatrix[2] | slice : 5 }} </span>
      </th>

      <td mat-cell *matCellDef="let element" [class.empty]="!element.days[2]">
        <div class="flex">
          <div 
            *ngIf="element?.days[2]?.type" 
            class="type"
            [ngClass]="element?.days[2]?.type == VisitTypes.BASIC ? 'green' : 'orange'"> 
            {{ translations[element?.days[2]?.type][0] | uppercase }} 
          </div>
          {{ element.days[2]?.customer.full_name }}
        </div>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="thursday">
      <th mat-header-cell *matHeaderCellDef>
        {{ translations['thursday'] | titlecase }} 
        <span> {{daysMatrix[3] | slice : 5 }} </span>
      </th>
      <td mat-cell *matCellDef="let element" [class.empty]="!element.days[3]">
        <div class="flex">
          <div 
            *ngIf="element?.days[3]?.type" 
            class="type"
            [ngClass]="element?.days[3]?.type == VisitTypes.BASIC ? 'green' : 'orange'"> 
            {{ translations[element?.days[3]?.type][0] | uppercase }} 
          </div>
          {{  element.days[3]?.customer.full_name }}
        </div>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="friday">
      <th mat-header-cell *matHeaderCellDef>
        {{ translations['friday'] | titlecase }}
        <span> {{daysMatrix[4] | slice : 5 }} </span>
      </th>
      <td mat-cell *matCellDef="let element" [class.empty]="!element.days[4]">
        <div class="flex">
          <div 
            *ngIf="element?.days[4]?.type" 
            class="type"
            [ngClass]="element?.days[4]?.type == VisitTypes.BASIC ? 'green' : 'orange'"> 
            {{ translations[element?.days[4]?.type][0] | uppercase }} 
          </div>
          {{  element.days[4]?.customer.full_name }}
        </div>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="saturday">
      <th mat-header-cell *matHeaderCellDef>
        {{ translations['saturday'] | titlecase }}
        <span> {{daysMatrix[5] | slice : 5 }} </span>
      </th>
      <td mat-cell *matCellDef="let element" [class.empty]="!element.days[5]">
        <div class="flex">
          <div 
            *ngIf="element?.days[5]?.type" 
            class="type"
            [ngClass]="element?.days[5]?.type == VisitTypes.BASIC ? 'green' : 'orange'"> 
            {{ translations[element?.days[5]?.type][0] | uppercase }} 
          </div>
          {{  element.days[5]?.customer.full_name }}
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="daysColumns"></tr>
    <tr 
      mat-row 
      *matRowDef="let row; 
      columns: daysColumns">
    </tr>
  </table>
  <div class="actions">
    <button *ngIf="calendarWeekIndex" (click)="goToPreviousWeek()" mat-button class="btn">
      {{ translations["back"] }}
    </button>
    <button (click)="goToNextWeek()" mat-button class="btn">
      {{ translations["forward"] }}
    </button>
  </div>
</div>