import { Injectable } from "@angular/core";
import { IMAGE_LIMITS } from "./settings.const";
import { FileErrors } from "./settings.model";

@Injectable()
export class SettingsService {

  public validateImage(image: Blob, errors: FileErrors, translations: any): void {
    if ( image.size > IMAGE_LIMITS.maxSize ) {
        errors.size = translations.content.image.errorSize;
    }
    if (!IMAGE_LIMITS.allowedTypes.includes(image.type.split('/')[1])) {
        errors.type = translations.content.image.errorType;
    }
  }

  public clearImageErrors(errors: FileErrors): void {
    errors.size = '';
    errors.type = '';
  }  


}