import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { AuthAPIService } from "../services/auth-api.service";
import { map, tap } from "rxjs";
import { UserBasicData } from "../auth.models";

export const isNotLoggedGuard = () => {
  const authAPIService = inject(AuthAPIService);
  const router = inject(Router);

	return authAPIService.getActiveUser()
	.pipe(
		tap((user: UserBasicData | boolean) => { if (user) { router.navigateByUrl('')}}),
		map((user: UserBasicData | boolean) => !Boolean(user)),
	)
} 