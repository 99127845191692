import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { BodySystem, Disease, Product, ProductsFilter, Recommendation } from '../../visit.models';
import { MatSelectChange } from '@angular/material/select';
import { Sort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Observable, Subscription, tap } from 'rxjs';
import { VisitAPIService } from '../../services/visit-api.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'cl-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})

export class ProductsComponent implements OnChanges, OnDestroy {
  @Input() recommendations: Recommendation[] = [];

  public subscriptions = new Subscription();
  public pathogenColumns = ['pathogen', 'activity', 'bodySystem', 'sympthoms'];
  public productColumns = ['product', 'description'];
  private selectedProducts: number[] = [];

  constructor(private visitAPIService: VisitAPIService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['recommendations'] && changes['recommendations'].currentValue.length) { 
      this.setSelectedProducts(changes);
      this.subscriptions.add(this.getProducts().subscribe());
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // TODO: this will come from translation service
  public text = {
    header: 'Produkty wspomagające',
    extra: 'Wybierz produkty wspomagające proces terapeutyczny',
    table: {
      name: 'Nazwa patogenu',
      activity: 'Stopień aktywności',
      bodySystem: 'Porażony system autonomiczny',
      symtoms: 'Objawy u pacjenta',
      products: 'Rekomendowane preparaty wspomagające',
      productName: 'Nazwa produktu',
      productDesc: 'Opis produktu',
    },
    applyForAll: 'Zastosuj dla wszystkich',
  };

  public selectedBodySystem(ev: MatSelectChange, disease: Disease): void {
    disease.selectedBodySystems = ev.value;
  }

  public sortData(sort: Sort, data: Disease[], table: MatTable<Disease>): void {
    data.sort((a: Disease, b: Disease) => {
      return (a.name < b.name ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
    });
    table.renderRows();
  }

  public applyForAll(recommendationIndex: number): void {
    this.recommendations[recommendationIndex].products
      .forEach((product: Product) => {
        this.recommendations.forEach((r: Recommendation) => {
          r.products.forEach((p: Product) => { if (product.id === p.id) { p.selected = product.selected } })
      })
    });
  }

  private getProducts(): Observable<any> {

    const filter: ProductsFilter = this.prepareProductsFilter();

    return this.visitAPIService.getProducts(filter).pipe(tap((res: HttpResponse<any>) => this.prepareSplitProductsBetweenSets(res.body)))
  }

  private prepareSplitProductsBetweenSets(products: Product[]): void {
    products.forEach((p: Product) => {
      p.selected = this.selectedProducts.includes(p.id);
      this.recommendations.forEach((r: Recommendation) => {
        if (r.diseases.some((d: Disease) => p.diseases?.includes(d.id))) { 
          r.products.push(structuredClone(p)) 
        }
      })
    });
  }

  private setSelectedProducts(changes: SimpleChanges): void {
    this.selectedProducts = [];      
    changes['recommendations'].previousValue.forEach((r: Recommendation) => {
      r.products.forEach((p: Product) => p.selected && this.selectedProducts.push(p.id))
    })
  }

  private prepareProductsFilter(): ProductsFilter {
    const bodySystems: number[] = [];
    const diseases: string[] = [];

    this.recommendations.forEach((r: Recommendation) => {
      r.diseases.forEach((d: Disease) => {
        diseases.push(d.id);
        d.bodySystems.forEach((bs: BodySystem) => bodySystems.push(bs.id));
      });
    });

    return { bodySystems, diseases }
  }

}
