import { Directive, EventEmitter, HostBinding, HostListener, Output } from "@angular/core";

@Directive({
    selector: '[clDropzone]'
})
export class DropzoneDirective {

  @HostBinding('class.fileover') fileOver: boolean = false;

  @Output() fileDropped: EventEmitter<Blob> = new EventEmitter();

  @HostListener('dragover', ['$event']) onDragover(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.fileOver = true; 
  }

  @HostListener('dragleave', ['$event']) onDragLeave(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) ondrop(e: DragEvent) {

    e.preventDefault();
    e.stopPropagation();
    this.fileOver = false;
    if(!e.dataTransfer) { return }

    const file: File = e.dataTransfer.files[0];
    const blob: Blob = new Blob([file], {type: file.type})
    if (blob) {
      this.fileDropped.emit(blob) 
    }
  }

}