<div *ngIf="text && user" class="home">
  <div class="top-bar">
    <div class="welcome">
      <div class="welcome-title">{{ text["greetingTitle"] }} {{ user.firstName }} </div>
      <div class="welcome-extra">
        {{ text["greetingExtraText"] }}
      </div>
    </div>
  </div>
  <ng-container *ngIf="user.role === UserRole.SPECIALIST">
    <div class="table-header" [class.mb]="!calendarVisits.length && selectedView !== VisitView.WEEK">
      <div class="left">
        <div class="left-title"> {{ text['scheduled'] }} </div>
        <div class="label">{{ text['visitsNumber'] }} {{ calendarVisits.length }}</div>
      </div>
      <div class="right">
        <cl-buttons-switch [items]="visitsFilters" (selectedButton)="changeVisitsFilter($event)"></cl-buttons-switch>
      </div>
    </div>

    <ng-container *ngIf="loadedData; else loading">
      <!-- WEEK VIEW TABLE  -->
      <cl-app-week 
      *ngIf="selectedView === VisitView.WEEK"
      [visits]="calendarVisits"
      [translations]="text"
      (changedWeek)="onWeekChanged($event)"
      >
    </cl-app-week>

      <!-- DAY VIEW TABLE  -->
      <table 
        *ngIf="calendarVisits?.length && selectedView !== VisitView.WEEK" 
        mat-table 
        [dataSource]="calendarVisits" 
        class="mat-elevation-z8">
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>{{ text['hour'] }}</th>
            <td mat-cell *matCellDef="let element" class="lighter-text">{{ element.hour }}</td>
          </ng-container>
          <ng-container matColumnDef="patient">
            <th mat-header-cell *matHeaderCellDef>{{ text['patient'] }}</th>
            <td mat-cell *matCellDef="let element">{{ element.customer.full_name }}</td>
          </ng-container>
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef>{{ text['phone'] }}</th>
            <td mat-cell *matCellDef="let element" class="lighter-text" >{{ element.patient.phone | phone }}</td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>{{ text['type'] }}</th>
            <td mat-cell *matCellDef="let element">
              <mat-form-field appearance="outline" class="select">
                <mat-select [(ngModel)]="element.type">
                  <mat-option
                    *ngFor="let type of VisitTypes"
                    [value]="type">
                    {{ text[type] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>{{ text['action'] }}</th>
            <td mat-cell *matCellDef="let element">
              <button (click)="startVisit(element)" class="btn-action">
                {{ text['start'] }}
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="visitsColumns"></tr>
          <tr 
            mat-row 
            *matRowDef="let row; 
            columns: visitsColumns" 
            [class.expired-visit]="row.expired">
          </tr>
      </table>

      <cl-info-bar
        *ngIf="!calendarVisits?.length && selectedView !== VisitView.WEEK" 
        [message]="text['noVisits']" 
        [type]="messageTypes.WARNING">
      </cl-info-bar>

    </ng-container>
  </ng-container>
</div>

<ng-template #loading>
  <mat-spinner class="loader"></mat-spinner>
</ng-template>
