import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { MatTabGroup } from '@angular/material/tabs';
import { catchError, EMPTY, Subscription, switchMap, tap, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

import { VisitTranslationsService } from '../services/translations.service';
import { VisitAPIService } from '../services/visit-api.service';
import { User } from 'src/app/auth/auth.models';
import { AuthAPIService } from 'src/app/auth/services/auth-api.service';
import {
  BodySystem,
  Disease,
  HealthFactor,
  Product,
  Readings,
  Recommendation,
  SelectionCard,
  Symptom,
  Visit,
  VisitType,
} from '../visit.models';

import { MESSAGE_TYPE, PDFFile, TranslationSet } from 'src/app/shared/models';
import { SNACKBAR_CONFIG } from 'src/app/shared/const';
import { ZipService } from 'src/app/shared/services/zip.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ModalPDFComponent } from 'src/app/shared/components/modal-pdf.component';

@Component({
  selector: 'cl-visit-view',
  templateUrl: './visit-view.component.html',
  styleUrls: ['./visit-view.component.scss'],
})
export class VisitViewComponent implements OnInit, OnDestroy {
  public visit: Visit = { readings: {} };
  public translations!: Record<string, TranslationSet>;
  public patient!: User;
  public healthFactors: HealthFactor[] = [];
  public bodySystems: BodySystem[] = [];
  public selectionCards: SelectionCard[] = [];
  public disableForwardTab = false;
  public disableBackTab = true;
  public displayPreviewVisitsHistory = false;
  public tabIndex: number = 0;
  // TODO: jest chyba użyte też do cecydowania co jest wysłane, 
  // być może można usunąć jeśli po portu będę zawsze wysyłał cały obiekt ?
  public readonlyTabs: Record<string, boolean> = {
    healthFactors: false,
  };
  public previousVisits: PDFFile[] = [];

  public todaysDate: Date = new Date();

  public annotations = '';
  public recommendations: Recommendation[] = [];
  public visitType = VisitType;

  private subscriptions = new Subscription();
  private readonly defaultActiveTabs = 4;

  constructor(
    private router: Router,
    private location: Location,
    private snackBar: MatSnackBar,
    private translationService: VisitTranslationsService,
    private authAPIService: AuthAPIService,
    private visitAPIService: VisitAPIService,
    private modalService: ModalService<ModalPDFComponent>,
    private zipService: ZipService) {
      this.assignVisitData();
    }

  ngOnInit(): void {
    this.subscriptions.add(this.getBodySystems());
    this.subscriptions.add(this.getHealthFactors());
    this.subscriptions.add(
      this.translationService
        .getTranslations()
        .subscribe(
          (t: Record<string, TranslationSet>) => (this.translations = t)
        )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get hasPathogens(): boolean {
    return this.selectionCards.some((sc: SelectionCard) => {
      return sc.diseases.some((p: Disease) => p.selected);
    });
  }

  get isLastTab(): boolean {
    return this.tabIndex === this.defaultActiveTabs + 1;
  }

  get isFirstTab(): boolean {
    return this.tabIndex === 0;
  }

  public goBack(): void {
    this.location.back();
  }

  public navigateTabs(tabs: MatTabGroup, forward: boolean): void {
    const change = forward ? 1 : -1;

    this.tabIndex = this.tabIndex + change;
    this.updateTabBtns(tabs);
  }

  public updateTabBtns(tabs?: MatTabGroup): void {
    const activeTabs = tabs ? tabs._allTabs.length - 3 : this.defaultActiveTabs;

    this.disableForwardTab =
      this.tabIndex === activeTabs ||
      (this.tabIndex === 4 && !this.visit.id) ||
      (this.tabIndex === 1 && !this.hasPathogens) ||
      (this.tabIndex === 2 && !this.recommendations.length);

    this.disableBackTab =
      this.tabIndex === 0 ||
      (this.tabIndex === 3 && !this.hasPathogens) ||
      (this.tabIndex === 4 && !this.recommendations.length);
  }

  public displayPDFModal(fileIndex: number): void {
    this.modalService.openDialog(
      this.previousVisits[fileIndex],
      ModalPDFComponent
    );
  }

  public getPreviousVisits(): void {
    if (!this.patient.id) return;

    this.subscriptions.add(
      this.visitAPIService
        .getVisitsHistory(this.patient.id)
        .pipe(
          switchMap((historyZip: Blob) => {
            return this.zipService.upnackZipedPDFs(historyZip);
          }),
          tap((files: PDFFile[]) => (this.previousVisits = files))
        )
        .subscribe()
    );
  }

  public save(): void {
    this.visit.id ? this.updateVisit() : this.saveNewVisit();
  }

  public recommendationsUpdate(r: Recommendation[]): void {
    this.recommendations = r;
    this.updateTabBtns();
  }

  public annotationsUpdate(annotation: string): void {
    this.annotations = annotation;
  }

  private updateVisit(): void {
    this.visit.readings = this.prepareReadings();

    const readings = this.clearReadingsData(
      structuredClone(this.visit.readings)
    );

    readings.visitId = this.visit.id;

    this.subscriptions.add(
      this.visitAPIService
        .updateVisit(readings)
        .pipe(
          tap(() => this.manageTabsState()),
          tap(() => this.snackBar.open(`${this.translations?.['visit']['success']}`, '', SNACKBAR_CONFIG)),
          catchError((e: HttpErrorResponse) => { 
            this.snackBar.open(
              `${this.translations?.['visit']['error']}: ${e.error.text} `,
              '',
              { ...SNACKBAR_CONFIG, panelClass: [MESSAGE_TYPE.ERROR] })
            return EMPTY;
          })
          )
        .subscribe()
    );
  }

  private prepareReadings(): Readings {
    const readings = {
      healthFactors: this.healthFactors.filter(
        (f: HealthFactor) => f.selectedValue
      ),
      pathogens: JSON.parse(JSON.stringify(this.selectionCards)),
      recommendations: this.recommendations,
      // recommendations: recommendationsMOCK as Recommendation[], TODO: delete
      annotations: this.annotations,
    };
    return readings;
  }

  private clearReadingsData(readings: Readings): Readings {
    if (readings.healthFactors) {
      readings.healthFactors.forEach(
        (h: Partial<HealthFactor>) => delete h.allowedValues
      );
    }

    if (readings.pathogens) {
      readings.pathogens.forEach((card: Partial<SelectionCard>) => {
        card.diseases = card.diseases?.filter((d: Disease) => d.selected);
        card.diseases &&
          card.diseases.forEach((d: Disease) => {
            d.symptoms = d.symptoms.filter((s: Symptom) => s.selected);
          });
      });
    }

    if (readings.recommendations) {
      readings.recommendations.forEach((r: Recommendation) => {
        if (r.products) r.products = r.products.filter((p: Product) => p.selected);
      })
    }


    return readings;
  }

  private saveNewVisit(): void {
    this.patient.birthdate = this.patient.birthdateDisplayed?.toISOString();

    this.visit = {
      ...this.visit,
      patient: (({ birthdateDisplayed, ...obj }) => obj)(this.patient),
      readings: this.prepareReadings(),
    };

    const readings = this.clearReadingsData(
      structuredClone(this.visit.readings)
    );

    this.subscriptions.add(
      this.visitAPIService
        .saveVisit({ ...this.visit, readings })
        .pipe(
          tap((res: HttpResponse<Record<string, string>>) => {
            this.visit.readings.visitId = this.visit.id = res!.body?.['id'];
            this.manageTabsState();
          }),
          tap(() => this.snackBar.open(`${this.translations?.['visit']['success']}`, '', SNACKBAR_CONFIG)),
          catchError((e: HttpErrorResponse) => {
            this.snackBar.open(
              `${this.translations?.['visit']['error']}: ${e.error.text}`,
              '',
              { ...SNACKBAR_CONFIG, panelClass: [MESSAGE_TYPE.ERROR] })
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  private manageTabsState(): void {
    Object.keys(this.visit.readings as Readings)
      .filter(
        (k: string) =>
          this.visit.readings &&
          this.visit.readings[k as keyof Readings]?.length
      )
      .forEach((k: string) => (this.readonlyTabs[k] = true));

    if (
      this.visit.readings.recommendations?.some((r: Recommendation) => {
        return r.diseases.some((d: Disease) => d.selectedBodySystems?.length);
      })
    ) {
      this.readonlyTabs['products'] = true;
    }
  }

  private setHealthFactorsAllowedValues(
    factors: HealthFactor[]
  ): HealthFactor[] {
    factors.forEach((factor: HealthFactor) => {
      factor.allowedValues = (factor.allowedValues as string).split(',');
    });
    return factors;
  }

  private getBodySystems(): Subscription {
    return this.visitAPIService
      .getBodySystems()
      .pipe(
        tap(
          (systems: HttpResponse<BodySystem[]>) =>
            (this.bodySystems = systems.body as BodySystem[])
        ),
        catchError((e) => throwError(() => e))
      )
      .subscribe();
  }

  private getHealthFactors(): Subscription {
    return this.visitAPIService
      .getHealthFactors()
      .pipe(
        tap(
          (factors: HttpResponse<HealthFactor[]>) =>
            (this.healthFactors = this.setHealthFactorsAllowedValues(
              factors.body as HealthFactor[]
            ))
        ),
        catchError((e) => throwError(() => e))
      )
      .subscribe();
  }

  private assignVisitData(): void {
    const navExtras = this.router.getCurrentNavigation()?.extras?.state;
    if (navExtras) {
      this.visit.type = navExtras?.['visitData']['type'];
      this.assignPatientData(navExtras['userData'] )
    }
  }

  private assignPatientData(userData: Record<string, any>): void {
    if (userData['userId']) {
      this.subscriptions.add(this.getPatient(userData['userId']));
      this.displayPreviewVisitsHistory = true;      
    } else {
      this.patient = userData as User;
    }
  }

  private getPatient(patientId: string): Subscription {
    return this.authAPIService
      .getAccountData(patientId)
      .pipe(
        tap((user: User) => {
          this.patient = user;
        }),
        catchError((err: HttpErrorResponse) => {
          this.snackBar.open(
            `${err.message} `,
            '',
            { ...SNACKBAR_CONFIG, panelClass: [MESSAGE_TYPE.ERROR] })
          return EMPTY;
        })
      )
      .subscribe();
  }
}
