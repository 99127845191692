<div class="bar">
  <div class="top">
    {{ text }}
    <span (click)="toggleOpen()" class="icon-container">
      <img
        class="icon"
        [class.rotated]="isOpen"
        src="assets/icons/arrow-left-pure.svg"
        alt="arrow"
      />
    </span>
  </div>

  <div *ngIf="showButtons" class="items">
    <button
      *ngFor="let item of items; let i = index"
      (click)="select(i)"
      class="btn"
    >
      {{ item.date | date : "dd MMMM yyyy" : "pl" }}
    </button>
  </div>
</div>
