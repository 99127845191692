import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PDFFile } from 'src/app/shared/models';

@Injectable()
export class ModalService<T> {
  constructor(public dialog: MatDialog) {}

  public openDialog(pdf: PDFFile, component: ComponentType<T>): void {
    const _dialogRef = this.dialog.open(component, {
      width: '800px',
      height: '900px',
      data: pdf,
    });
  }
}
