import { User } from '../auth/auth.models';

export enum DeviceContract {
  SALES = 'sales',
  RENTAL = 'rental',
  NONE = ''
}

export interface PdfConfig { 
  contract: DeviceContract, 
  device: string, 
  fullPreview: boolean 
}

export interface HealthFactor {
  name: string;
  description: string;
  allowedValues: string[] | string;
  selectedValue?: string;
}

export interface DataSource {
  id: number;
  name: string;
}

export interface BodySystem extends DataSource {
  selected?: boolean;
  diseases?: Disease[];
}

export interface DiseaseCategory extends DataSource {
  data_source: any;
}

export interface DiseaseSubCategory extends DataSource {
  category: number;
}

export interface Symptom {
  name: string;
  selected: boolean;
}

export interface Disease {
  id: string;
  name: string;
  description: string;
  bodySystems: BodySystem[];
  dataSources: DataSource[];
  categories: DiseaseCategory[];
  subCategories: DiseaseSubCategory[];
  symptoms: Symptom[];
  frequencies: Frequency[];
  selected: boolean;
  filteredFrequencies?: Frequency[];
  selectedFrequencies?: Frequency[];
  selectedDataSource?: DataSource | null;
  selectedBodySystems?: BodySystem[];
  activity?: number;
  selectedSet?: SetItem[];
  categoryPath?: string;
}

export interface SetItem {
  name: string;
  active: boolean;
}

export interface Frequency {
  id: string;
  name: string;
  polarity: number;
  value: number;
  wave: number;
  dataSourceId: number;
  diseaseId?: string;
}

export enum VisitType {
  BASIC = 'basic',
  CONTROL = 'control'
}

export interface Visit {
  id?: string;
  type?: VisitType;
  patient?: User;
  readings: Readings;
}

export interface Readings {
  visitId?: string;
  healthFactors?: Partial<HealthFactor>[];
  pathogens?: Partial<SelectionCard>[];
  recommendations?: Recommendation[];
  annotations?: string;
}

export interface SelectionCard {
  title: string;
  diseases: Disease[];
}

export interface RecommendationUnit {
  name: string;
  values: string[];
  selectedValue: string;
}

export interface ConfigItem {
  name: string;
  values: string[];
  selected: string | null;
  order: number;
}

export interface ProductsFilter {
  bodySystems: number[];
  diseases: string[];
}

export interface Product {
  id: number,
  name: string,
  description: string,
  diseases?: string[],
  selected?: boolean,
}

type RecommendationConfigKey =
  | 'setsAmount'
  | 'pathogenesPerSet'
  | 'setTime'
  | 'frequencyTime'
  | 'powerRange';

export type RecommendationsConfig = {
  [key in RecommendationConfigKey]: ConfigItem;
};

export class Recommendation {
  setName: string;
  diseases: Disease[];
  config: Partial<RecommendationsConfig>;
  products: Product[] = [];

  constructor(
    setName: string,
    config: Partial<RecommendationsConfig>,
    diseases: Disease[],
  ) {
    this.setName = setName;
    this.config = config;
    this.diseases = diseases;
  }
}
