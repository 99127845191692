import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, filter, Observable, skip, Subscription, switchMap, tap } from 'rxjs';
import { AVATAR_PATH, LOGO_PATH } from '../auth/auth.const';
import { UserBasicData } from '../auth/auth.models';
import { AuthAPIService } from '../auth/services/auth-api.service';

type AvailableRoute = '/' | '/patients' | '/faq' | '/settings';

@Component({
  selector: 'cl-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren('selectable') buttons!: QueryList<ElementRef>;

  public logoPath = '';
  public avatarPath = '';
  public loggedIn = false;
  public user: UserBasicData | null = null;
  public selectedNavBtn = 0;

  private readonly routesButtonsMap: Record<AvailableRoute, number> = {
    '/': 0,
    '/patients': 1,
    '/faq': 2,
    '/settings': 3 
  };
  private buttonsRefs: ElementRef[] = [];
  private subscriptions: Subscription = new Subscription();

  constructor(private router: Router, private authAPIService: AuthAPIService) {}

  ngOnInit(): void {
    this.subscriptions.add(this.getActiveUserData().subscribe());
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(this.getButtons().subscribe())    
  }  

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public goHome(): void {
    this.selectedNavBtn = 0;
    this.updateButtons();
    this.router.navigateByUrl('');
  }

  public getMyPatients(): void {
    this.selectedNavBtn = 1;
    this.updateButtons();
    this.router.navigateByUrl('patients');
  }
  public getHelp(): void {
    this.selectedNavBtn = 2;
    this.updateButtons();
    this.router.navigateByUrl('faq');
  }
  public getSettings(): void {
    this.selectedNavBtn = 3;
    this.updateButtons();
    this.router.navigateByUrl('settings');
  }

  public logout(): void {
    this.subscriptions.add(
      this.authAPIService.logout().subscribe(() => {
        this.handleLogout();
      })
    );
  }

  private updateButtons(): void {
    this.buttonsRefs.forEach((b: ElementRef, index: number) => {
      if (this.selectedNavBtn === index) {
        b.nativeElement.classList.add('selected');  
      } else {
      b.nativeElement.classList.remove('selected');
      }
    })
  }

  private getAvatar(): Observable<Blob | null> {
    return this.authAPIService.getAvatar().pipe(
      switchMap(() => this.authAPIService.$avatar),
      tap((a: Blob | null) => {
        if (!a) {this.avatarPath = AVATAR_PATH; return }

        const reader = new FileReader();
        reader.readAsDataURL(a);
        reader.onload = (event) => this.avatarPath = event.target?.result as string;
      }))
  }

  private getLogo(): Observable<Blob | null> {
    return this.authAPIService.getLogo().pipe(
      switchMap(() => this.authAPIService.$logo),
      tap((logo: Blob | null ) => {
        if (!logo) {this.logoPath = LOGO_PATH; return }

        const reader = new FileReader();
        reader.readAsDataURL(logo);
        reader.onload = (event) => this.logoPath = event.target?.result as string;
      }),
      catchError((_err) => { this.logoPath = LOGO_PATH; return EMPTY; } )
    )
  }

  private getActiveUserData(): Observable<Blob | null> {
    return this.authAPIService.$activeUser
      .pipe(
        skip(1),
        tap((user: UserBasicData | null) => this.handleUserAuthChange(user)), 
        switchMap(() => this.getAvatar()),
        switchMap(() => this.getLogo()),
        catchError((_err) => EMPTY)
      )
  }

  private handleLogout(): void {
    this.loggedIn = false;
    this.router.navigateByUrl('auth/login');
  }

  private handleUserAuthChange(user: UserBasicData | null): void {
    if (Boolean(user)) {
      this.user = user;
      this.loggedIn = true;
    } else {
      this.handleLogout();
    }
  }

  private getButtons(): Observable<unknown> {
    return this.buttons.changes.pipe(
      tap((updated: QueryList<ElementRef[]>) => this.buttonsRefs = updated['_results']),
      switchMap(() => this.router.events),
      filter((e: any) => e['routerEvent']?.['url']),
      tap((e: any) => {
        const route: AvailableRoute = this.cutFullUrlToModuleUrl(e['routerEvent']?.['url']);
        this.selectedNavBtn = this.routesButtonsMap[route];
        this.updateButtons();
      })
    )
  }


  private cutFullUrlToModuleUrl(currentURL: AvailableRoute): AvailableRoute {
    let result = currentURL;
    Object.keys(this.routesButtonsMap).forEach((key: string) => {
      if (currentURL.includes(key)) result = key as AvailableRoute; 
    })
    return result;
  }

}
